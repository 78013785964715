import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOne(fields: string[]): ValidatorFn {
 
  return (control: AbstractControl): ValidationErrors | null => {
    var error = {atLeastOne: fields};
 
    if(!control){
      return error;
    }

    var value;
    var hasAnyValue = false;

    fields.forEach(field => {
      value = control.get(field).value;
      if(value != null && value != ''){
        hasAnyValue = true;
      }
    });

    if(hasAnyValue){
      return null;
    }else{
      return error;
    }
  }
 
}
