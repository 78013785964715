
import { TableService } from "src/app/_metronic/shared/crud-table";
import { Observable } from "rxjs";
import { ApiResponse } from "../../../_metronic/shared/models/api-response";
import { Lead, LeadNote } from "../_dto/lead-dto";
import { Enums } from "../../../_metronic/shared/models/enum-item";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export abstract class ILeadsService extends TableService<Lead> {

  abstract changeStatus(id: number, value: number): Observable<ApiResponse>;

  abstract getLead(id, fromMail): Observable<ApiResponse<Lead>>;

  abstract getEnums():any;

  abstract canBuyLead(lead: Lead): boolean;

  abstract priceSum(lead:Lead, removeRefunded?: boolean );

  abstract hasEnoughPointsToBuy(lead: Lead): boolean;

  abstract createNewNote(lead: Lead): NgbModalRef;

  abstract editLeadNote(note: LeadNote): NgbModalRef;

  abstract beginPurchase(lead: Lead): void;

  abstract finaliseComplaint(leadId, complaint): Observable<ApiResponse<string>>;

  abstract getPhone(id): Observable<ApiResponse<string>>;
}
