import { UpdateProfileInformationDto } from "../_dto/update-profile-information-dto";
import { Observable } from "rxjs";
import { ApiResponse } from "../../../_metronic/shared/models/api-response";
import { UpdateAddressInformationDto } from "../_dto/update-address-information-dto";
import { UpdateInvoiceInformationDto } from "../_dto/update-invoice-information-dto";
import { ChangePasswordDto } from "../../auth/_dto/change-password-dto";
import { ChangeEmailDto } from "../../auth/_dto/change-email-dto";
import { ProfileNotificationsDto } from "../_dto/notifications-information-dto";
import { UpdateProductsDto } from "../_dto/update-products-dto";
import { AgreementDto } from "src/app/_dto/agreement-dto";
import { ProductsDto } from "src/app/_dto/products-dto";
import { CategoryConfigDto } from "src/app/_dto/category-config-dto";

export abstract class IUserProfileService {

  abstract getCategoryConfig(): Observable<ApiResponse<CategoryConfigDto>>;

  abstract updateProfileInformation(data: UpdateProfileInformationDto): Observable<ApiResponse>;

  abstract updateAddressInformation(data: UpdateAddressInformationDto): Observable<ApiResponse>;

  abstract updateInvoiceInformation(data: UpdateInvoiceInformationDto): Observable<ApiResponse>;

  abstract updateNotificationsSettings(data: ProfileNotificationsDto, agreements: AgreementDto[]): Observable<ApiResponse>;

  abstract updateProducts(data: UpdateProductsDto): Observable<ApiResponse>;

  abstract changePassword(data: ChangePasswordDto): Observable<ApiResponse>;

  abstract changeEmail(data: ChangeEmailDto): Observable<ApiResponse>;

  abstract disableNewLeadMail(hash: string, user_id: string): Observable<ApiResponse>;

}
