import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-category-display',
  template: `
    <ng-template #tooltipContent>
      <span class="text-left">
        <strong>{{ 'LEADS.LEAD_LIST.ACTIONS.EXTRA_INFO' | translate }}</strong>
        <hr />
        <p [innerHTML]="value.extra_info || '-'"></p>
      </span>
    </ng-template>
    <i
      class="btn btn-icon btn-light btn-hover-primary btn-sm"
      [ngClass]="{ 'disabled': !value.extra_info }"
      title="{{ 'LEADS.LEAD_LIST.ACTIONS.EXTRA_INFO' | translate }}"
      [ngbTooltip]="value.extra_info ? tooltipContent : ''"
      tooltipClass="my-custom-class"
      placement="{{ mobile ? 'auto' : 'left' }}"
    >
      <span
        [inlineSVG]="'./assets/media/svg/icons/Code/Info-circle.svg'"
        cacheSVG="true"
        class="svg-icon svg-icon-md"
        [ngClass]="value.extra_info ? 'svg-icon-primary' : ''"
      >
      </span>
    </i>
  `,
})
export class BellActionComponent extends BaseDisplayComponent<Lead> {}
