import { Validators } from "@angular/forms";
import { HundredMultipeValidator } from "src/app/_validators/hundredMultipeValidator";

export const SettlementsConfig = {
  headers: [
    {
      childRoute: true,
      route: '/top-up',
      translation: 'TOP_UP'
    },
    {
      childRoute: true,
      route: '/payment-history',
      translation: 'PAYMENT_HISTORY'
    },
    {
      childRoute: true,
      route: '/operations-history',
      translation: 'OPERATIONS_HISTORY'
    },
    {
      childRoute: true,
      route: '/refund-history',
      translation: 'REFUND_HISTORY'
    },
    {
      childRoute: true,
      route: '/invoices',
      translation: 'INVOICES'
    },
  ],
  topUp: {
    pointPrice: 1,
    vatValue: 23,
    packages:
      [{
        price: 12.34,
        points: 50,
        name: {
          pl: 'Pozycja 1',
          en: 'Position 1'
        },
        nameString: null,
        desc: {
          pl: 'Opis 1',
          en: 'Desc 1'
        },
        descString: null,
        type: 'PACK1'
      },{
        price: 40,
        points: 150,
        name: null,
        nameString: 'BASIC',
        desc: null,
        descString: 'BASIC_DESC',
        type: 'PACK2'
      }],
    enablePackages: false,
    options: [
      {amount:200, promotionFactor: 1}, 
      {amount:300, promotionFactor: 1}, 
      {amount:500, promotionFactor: 1.1}, 
      {amount:1000, promotionFactor: 1.15}
    ],
    promotions:[
      
    ],
    validators: [
      HundredMultipeValidator(),
      Validators.min(200),
      Validators.max(10000),
      Validators.required
    ],
    autoRedirectDelay: 10000,
    redirectUrl: '/settlements/top-up'
  },
  invoicePrefix: 'leadguru_fv_'
}
