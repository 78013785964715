import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { environment, mapEnvironment } from '../environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeAPIService } from './_fake/fake-api.service';
import { APP_CONFIG, MAP_CONFIG } from './app-config';
import { USER_DROPDOWN_GLOBAL_CONFIG } from './_metronic/partials/layout/extras/dropdown-inner/user-dropdown-inner/user-dropdown-item';
import { UserDropdownConfig } from '../environments/user-dropdown';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./_metronic/shared/shared.module";
import { AppInterceptors } from "./interceptors/app.interceptors";
import { DASHBOARD_GLOBAL_CONFIG } from './pages/dashboard/dashboard-config';
import { DashboardConfig } from '../environments/dashboard';
import { REGISTRATION_GLOBAL_CONFIG } from './modules/auth/registration/registration-config';
import { RegistrationConfig } from '../environments/registration';
import { ProductsConfig } from '../environments/products';
import { SETTLEMENTS_GLOBAL_CONFIG } from './modules/settlements/settlements-config';
import { SettlementsConfig } from 'src/environments/settlements';
import { PRODUCTS_GLOBAL_CONFIG } from './modules/products/products-config';
import { USER_PROFILE_GLOBAL_CONFIG } from './modules/user-profile/user-profile-config';
import { UserProfileConfig } from 'src/environments/user-profile';
import { LEADS_GLOBAL_CONFIG } from './modules/leads/leads-config';
import { LeadsConfig } from 'src/environments/leads';
import { TranslateUtils } from "./_metronic/core/utils/translate.utils";
import { Router } from "@angular/router";
import { IAuthService } from "./modules/auth";
import { AuthService } from "./modules/auth/_services/auth.service";
import { FakeAuthService } from "./modules/auth/_services/fake-auth.service";
import { NgSelectConfig } from "@ng-select/ng-select";
import { TimeagoIntl } from 'ngx-timeago';
import { strings as stringsPl } from "ngx-timeago/language-strings/pl";
import { strings as stringsEn } from "ngx-timeago/language-strings/en";
import { IonicModule } from '@ionic/angular';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class MyIntl extends TimeagoIntl {
  strings = localStorage.getItem('language') == 'pl' ? stringsPl : stringsEn;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot({
      extend: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    HttpClientModule,
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SharedModule,
    IonicModule.forRoot(),
  ],
  providers: [
    ...AppInterceptors,
    {
      provide: APP_CONFIG,
      useValue: environment
    },
    {
      provide: MAP_CONFIG,
      useValue: mapEnvironment
    },
    {
      provide: USER_DROPDOWN_GLOBAL_CONFIG,
      useValue: UserDropdownConfig
    },
    {
      provide: USER_PROFILE_GLOBAL_CONFIG,
      useValue: UserProfileConfig
    },
    {
      provide: DASHBOARD_GLOBAL_CONFIG,
      useValue: DashboardConfig
    },
    {
      provide: REGISTRATION_GLOBAL_CONFIG,
      useValue: RegistrationConfig
    },
    {
      provide: PRODUCTS_GLOBAL_CONFIG,
      useValue: ProductsConfig
    },
    {
      provide: SETTLEMENTS_GLOBAL_CONFIG,
      useValue: SettlementsConfig
    },
    {
      provide: LEADS_GLOBAL_CONFIG,
      useValue: LeadsConfig
    },
    { provide: IAuthService, useClass: environment.production ? AuthService : FakeAuthService }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(translate: TranslateService,
              router: Router,
              selectConfig: NgSelectConfig) {
    TranslateUtils.reload(translate);
    router.routeReuseStrategy.shouldReuseRoute = () => false;

    translate.get('SHARED.NO_OPTIONS')
      .subscribe(t => selectConfig.notFoundText = t);

    translate.get('SHARED.TYPE_TO_SEARCH')
      .subscribe(t => selectConfig.typeToSearchText = t);

    translate.get('SHARED.LOADING_TEXT')
      .subscribe(t => selectConfig.loadingText = t);
  }
}
