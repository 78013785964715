import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  private translate: TranslateService;

  constructor(injector: Injector) {
    this.translate = injector.get(TranslateService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.assetRequested(request.url)) {
      return next.handle(request);
    }

    const currentLang = localStorage.getItem('language') || this.translate.getDefaultLang();
    let headers = request.headers || new HttpHeaders();
    headers = headers.set('X-Locale', currentLang);

    const cloned = request.clone({
      headers
    });

    return next.handle(cloned);
  }

  private assetRequested(url: string): boolean {
    return url.includes('assets');
  }
}
