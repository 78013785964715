import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductBaseComponent } from '../product-base.component';
import { ProductComponentInterface } from '../product-component-interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import { IProductsService } from '../../_services/products-service-interface';
import KTWizard from '../../../../../assets/js/components/wizard';
import { KTUtil } from '../../../../../assets/js/components/util';
import { tin } from 'src/app/_validators/tin.validator';
import { AddressUtil } from 'src/app/_metronic/core/utils/address.util';
import { Address } from 'src/app/modules/auth/_models/address.model';
import { BasicProductDto } from '../../_dto/basic-product-dto';
import { atLeastOne } from 'src/app/_validators/at-least-one.validator';
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-product',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent extends ProductBaseComponent implements ProductComponentInterface, AfterViewInit, OnDestroy {

  @ViewChild('wizard', { static: true }) el: ElementRef;

  submitted = false;
  wizard: any;

  steps: FormGroup[];

  agreements: AgreementDto[];
  show = [];
  allChecked: boolean = false;

  constructor(
    protected productsService: IProductsService,
    protected fb: FormBuilder,
    protected message: MessageService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected router: Router
  ) {
    super(productsService, fb, message, router);
    this.type = 'basic';
  }
  
  checkAll(){
    this.agreements.forEach(agree => {
      this.steps[3].patchValue({['agreement' + agree.id] : this.allChecked } );
    });
  }

  prepareGroup(){
    this.steps = [];

    this.steps[1] = this.fb.group({
      companyName: [this.existingData?.companyName || '', Validators.required],
      tin: [this.existingData?.tin || '', Validators.compose([Validators.required, tin()])],
      address: this.fb.group(AddressUtil.getFormControls(this.existingData?.address || new Address())),
    });

    this.steps[2] = this.fb.group({
      useInvoiceAsContact: [this.existingData?.useInvoiceAsContact == null ? true : this.existingData?.useInvoiceAsContact ],
      contactAddress: this.fb.group(AddressUtil.getFormControls(this.existingData?.contactAddress || new Address())),
    });

    this.steps[3] = this.fb.group({
      agentId: [this.existingData?.agentId || ''],
      workerId: [this.existingData?.workerId || '']
    },{
      validator: atLeastOne(['agentId','workerId']),
    });

    this.agreements = this.existingData?.agreements || [];

    this.agreements.forEach(agree => {
      this.steps[3].addControl('agreement' + agree.id, new FormControl(agree.accepted, agree.required ? Validators.requiredTrue : null));
    });

    this.steps[2].get('useInvoiceAsContact').valueChanges.subscribe(val => {
      if (val) {
        this.steps[2].controls['contactAddress'].disable();
      } else {
        this.steps[2].controls['contactAddress'].enable();
      }
      this.steps[2].controls['contactAddress'].updateValueAndValidity();
    });

    this.steps[2].controls['useInvoiceAsContact'].updateValueAndValidity({ onlySelf: false, emitEvent: true });

    this.changeDetectorRef.detectChanges();
  }

  getFormValues() {
    var output = new BasicProductDto();
    output.companyName = this.steps[1].controls['companyName'].value;
    output.tin = this.steps[1].controls['tin'].value;
    output.address = this.steps[1].controls['address'].value;
    
    output.useInvoiceAsContact = this.steps[2].controls['useInvoiceAsContact'].value;
    output.contactAddress = this.steps[2].controls['contactAddress'].value;
    
    output.agentId = this.steps[3].controls['agentId'].value;
    output.workerId = this.steps[3].controls['workerId'].value;

    this.agreements.forEach(agree => {
      output.agreements.push(new AgreementDto(agree.id, agree.content, agree.required, this.steps[3].controls['agreement' + agree.id].value, agree.disclaimer));
    });
    return output;
  }

  // helpers for View
  isControlValid(step: string, controlName: string, controlParent:string = null): boolean {
    var base:any = this.steps[step];
    if(controlParent){
      var base:any = base.controls[controlParent];
    }
    const control = base.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(step: string, controlName: string, controlParent:string = null): boolean {
    var base:any = this.steps[step];
    if(controlParent){
      var base:any = base.controls[controlParent];
    }
    const control = base.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  ngAfterViewInit(): void {

    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });
    
    // Validation before going to next page
    this.wizard.on('change', (wizardObj) => {
      if(!this.steps){
        wizardObj.stop();
        return;
      }
      if(!this.validateStep(wizardObj.currentStep)){
        wizardObj.stop();
        return;
      }
      this.changeDetectorRef.detectChanges();
    });
    
  }

  validateStep(step){
    this.steps[step].markAllAsTouched();
    this.steps[step].markAsDirty();
    if(this.steps[step].invalid){
      this.changeDetectorRef.detectChanges();
      return false;
    }
    return true;
  }

  onSubmit(){
    if(!this.validateStep(3)){
      return;
    }
    this.submit();
  }

  ngOnDestroy() {
    this.wizard = undefined;
  }

}
