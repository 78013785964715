import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-state-filter',
  template: `
  <div [formGroup]="form">
    <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.STATE' | translate }}</small>
    <select class="form-control" name="state" formControlName="state">
      <option value="" [ngValue]="null">{{ 'GLOBAL.ALL' | translate }}</option>
      <option value="{{state.value}}" *ngFor="let state of enums.states">{{state.label}}</option>
    </select>
  </div>
  `
})
export class StatesFilterComponent extends BaseFilterComponent{

  public static formName = ['state'];

  registerForm(){
    this.form.addControl('state', new FormControl(null));

    this.subscriptions.push(
      this.form.controls.state.valueChanges.subscribe(() =>{
        this.filter.emit();
      }
    )
    );
  }
  
}