import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';
import { ILeadsService } from "../../_services/leads-service-interface";

@Component({
  selector: 'app-buy-action',
  template: `
    <button [attr.data-cy]="'purchase_'+value.id" class="btn btn-sm btn-primary mr-2" *ngIf="leadService.canBuyLead(value)"
            (click)="buyLead()">
      <span *ngIf="value.bought; else normalBuyButton">
        {{ 'LEADS.LEAD.BUY_EXTRA' | translate }}
      </span>
      <ng-template #normalBuyButton>
        {{ 'LEADS.LEAD.BUY_NOW' | translate }}
      </ng-template>
    </button>
  `
})
export class BuyActionComponent extends BaseDisplayComponent<Lead> {

  constructor(public leadService: ILeadsService) {
    super();
  }

  buyLead() {
    this.leadService.beginPurchase(this.value);
  }
}
