export class ApiResponse<T = void> {
  status: number;
  message: string;
  data?: T;

  constructor() {
    this.status = 1;
    this.message = '';
    this.data = undefined;
  }
}
