import { AgreementDto } from "src/app/_dto/agreement-dto";
import { ProductsDto } from "src/app/_dto/products-dto";
import { MessagesDto } from "../../user-profile/_dto/messages-dto";
import { ProfileNotificationsDto } from "../../user-profile/_dto/notifications-information-dto";
import { Address } from "./address.model";
import { InvoiceInformation } from "./invoice-information.model";
import { Refresh } from "./refresh.model";

export class User extends Refresh{
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  points: number;
  refundPoints: number;
  locale: string;
  address: Address;
  invoiceInformation: InvoiceInformation;
  notificationsSettings: ProfileNotificationsDto;
  serviceLocationsCount: number;
  agreements: AgreementDto[];
  products: ProductsDto[];
  agentId: string;
  workerId: string;
  messages: MessagesDto;
  enumVersion: string;
  override: boolean;
  active: string;

  constructor() {
    super();
    this.address = new Address();
    this.invoiceInformation = new InvoiceInformation();
    this.notificationsSettings = new ProfileNotificationsDto();
    this.messages = new MessagesDto();
    this.products = [];
    this.agreements = [];
  }

  setUser(user: any) {
    this.id = user.id;
    this.firstName = user.firstName || '';
    this.lastName = user.lastName || '';
    this.email = user.email || '';
    this.avatar = user.avatar || './assets/media/users/default.jpg';
    this.phone = user.phone || '';
    this.points = user.points || 0;
    this.refundPoints = user.refundPoints || 0;
    this.locale = user.locale || 'pl';
    this.active = user.active || 'ACTIVATED';
    this.agentId = user.agentId;
    this.workerId = user.workerId;
    this.override = user.override || false;
    this.enumVersion = user.enumVersion || '';

    this.products = user.products || [];
    this.agreements = user.agreement || [];
    this.address.setAddress(user.address || {});
    this.invoiceInformation.setInvoiceInformation(user.invoiceInformation || {});
    this.notificationsSettings.setProfileNotifications(user.notificationsSettings || {});
    this.messages.setMessages(user.messages || {});
  }
}
