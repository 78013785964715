import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-search-term-filter',
  template: `
    <div [formGroup]="form">
      <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.SEARCH_TERM' | translate }}</small>
      <input type="text" class="form-control" name="searchTerm" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.SEARCH_TERM' | translate }}" value=""
        formControlName="searchTerm" />
    </div>
  `
})
export class SearchTermFilterComponent extends BaseFilterComponent{

  public static formName = ['searchTerm'];

  registerForm(){
    this.form.addControl('searchTerm', new FormControl(''));

    this.subscriptions.push(
      this.form.controls.searchTerm.valueChanges.pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.filter.emit())
    );
  }
  
}