<div class="mb-3">
  <div class="text-center mb-4">
    <i class="flaticon-questions-circular-button text-primary icon"></i>
  </div>

  <mat-dialog-content class="text-center font-size-lg" [innerHTML]="data.text"></mat-dialog-content>

  <div mat-dialog-actions class="d-flex justify-content-center mt-3">
    <button class="btn btn-primary" mat-dialog-close (click)="confirmed()">{{ data.confirmButton || '' }}<span *ngIf="!data.confirmButton">{{ 'SHARED.CONFIRM_TEXT' | translate }}</span></button>
    <button class="btn btn-light ml-2" mat-dialog-close (click)="rejected()">{{ data.rejectButton || '' }}<span *ngIf="!data.confirmButton">{{ 'SHARED.CANCEL_TEXT' | translate }}</span></button>
  </div>
</div>
