<div class="card border-0">

  <ng-template #exclusive><strong>{{ 'LEADS.LEAD.EXCLUSIVE' | translate }}</strong></ng-template>

  <div class="card-header p-5 d-flex justify-content-between align-items-center">
    <h4 class="text-primary mb-0" *ngIf="!complete; else completeTitle">{{ 'LEADS.COMPLAINT.TITLE' | translate }}</h4>
    <ng-template #completeTitle>
      <h4 class="text-primary mb-0">{{ 'LEADS.COMPLAINT.COMPLETE_TITLE' | translate }}</h4>
    </ng-template>

    <div class="card-toolbar">
        <button class="btn btn-xs btn-default" (click)="onBack()">{{ 'GLOBAL.CLOSE' | translate }}</button>
    </div>
  </div>

    <div #wizard class="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" [hidden]="lead?.complaint?.id || complete">
    <div class="kt-grid__item">
      <!--begin: Form Wizard Nav -->
      <div class="wizard-nav border-bottom mb-5 mx-5 mx-md-8">
        <div class="wizard-steps py-8 py-lg-3">
          <a class="wizard-step cursor-default" href="javascript:;" data-wizard-type="step" data-wizard-state="current" >
            <div class="wizard-label pl-0">
              <div class="wizard-title">
                <span>1</span>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_1' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
          <a class="wizard-step cursor-default" href="javascript:;" data-wizard-type="step">
            <div class="wizard-label">
              <div class="wizard-title">
                <span>2</span>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_2' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
          <a class="wizard-step cursor-default" href="javascript:;" data-wizard-type="step">
            <div class="wizard-label">
              <div class="wizard-title">
                <span>3</span>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_3' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
        </div>
      </div>
      <!--end: Form Wizard Nav -->
    </div>
    <div >
      <div class="mb-5 mx-5 mx-md-8">
        <form class="form" id="kt_form">
          <!--begin: Form Wizard Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current" [formGroup]="steps[1]" >
            <p class="mb-0 font-size-h6 lh-1.4"><strong class="text-primary">{{ lead.first_name }} {{ lead.last_name }}</strong> - Lead {{ lead.category.name }} ID <strong>#{{ lead.id }}</strong></p>
            <p class="font-size-h6 mb-5"><strong>{{ lead.location.postalCode }} {{ lead.location.city }}</strong> ({{ lead.location.province }})</p>
            <p class="mb-0 font-size-lg lh-1.4">
              {{ 'LEADS.COMPLAINT.CURRENT_POINTS' | translate }} <strong class="text-primary">{{ startBalance }} {{ 'LEADS.COMPLAINT.POINTS' | translate }}</strong>
            </p>
            <div class="form-group mb-5">
              <p class="mb-4 font-size-lg lh-1.4">
                <strong>{{'LEADS.COMPLAINT.SELECT_PURCHASE' | translate}}</strong>, {{'LEADS.COMPLAINT.THAT_YOU_PREFER' | translate}}:
              </p>

              <div *ngFor="let ic of lead.bought_for; let i=index" class="ml-3">
                <label *ngIf="ic.refundable" class="mb-0 d-flex align-items-start cursor-pointer">
                  <input type="checkbox" formArrayName="ics" class="checkbox-input mr-2 hue" [value]="i" (change)="onCheckboxChange($event)" [disabled]="balance <= 0 && !selectedAmounts[i]" />
                  <p class="lh-1.4 mb-2" [ngClass]="{ 'diss': (balance <= 0 && !selectedAmounts[i]) }">
                    <strong *ngIf="ic?.short_name; else exclusive">{{ ic.short_name }}</strong> 
                    (<strong class="highlight">
                      <span [ngClass]="{ 'diss': ((ic.price != selectedAmounts[i]) && selectedAmounts[i]) }">{{ic.price}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                      <span *ngIf="(ic.price != selectedAmounts[i]) && selectedAmounts[i]"> {{selectedAmounts[i]}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                    </strong>) - {{ 'LEADS.COMPLAINT.PURCHASE_DATE' | translate }} <strong>{{ic.created_at}}</strong>
                  </p>
                </label>
              </div>

              <!-- <div>
                {{ steps[1].value | json }}
                {{ selectedAmounts |json }}
                <p class="mt-5 mb-0">Suma reklamacji: {{ startBalance - balance }}</p>
                <p>Saldo po reklamacji: {{ balance }}</p>
              </div> -->

              <ng-container *ngIf="steps[1].controls['ics'].errors && steps[1].controls['ics'].touched">
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block text-danger">
                    {{ 'LEADS.COMPLAINT.COMPANY_REQUIRED' | translate }}
                  </div>
                </div>
              </ng-container>

              <div class="alert alert-light mt-2" role="alert" *ngIf="reduced">
                <strong>{{ 'LEADS.COMPLAINT.WARNING' | translate }}</strong>: {{ 'LEADS.COMPLAINT.REDUCED_REFUND' | translate }}
              </div>              

            </div>
          </div>
          <!--end: Form Wizard Step 1-->

          <!--begin: Form Wizard Step 2-->
          <div data-wizard-type="step-content" data-wizard-state="current" [formGroup]="steps[2]" >
            
            <div class="form-group mb-5">
              <label>
                {{'LEADS.COMPLAINT.reason' | translate}}:
              </label>

              <ng-select
                [items]="reasons" 
                [placeholder]="'LEADS.COMPLAINT.REASON_PLACEHOLDER' | translate"
                [clearable]="false" 
                [loading]="isLoading$ | async" 
                [disabled]="isLoading$ | async"
                formControlName="reason" 
                [class.is-invalid]="checkControl(false, 2,'reason')"
                [class.is-valid]="checkControl(true, 2,'reason')">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  {{'LEADS.COMPLAINT.OPTIONS.'+item | translate}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{'LEADS.COMPLAINT.OPTIONS.'+item | translate}}
                </ng-template>
              </ng-select>

              <span class="text-gray">
                {{'LEADS.COMPLAINT.INFO.REASON' | translate}}
              </span>
            </div>

            <div class="form-group mb-5" *ngIf="steps[2].controls['reason'].value == 'OTHER'">
              <label>
                {{'LEADS.COMPLAINT.desc' | translate}}:
              </label>

              <textarea
                class="form-control"
                maxlength="500"
                formControlName="desc" 
                [class.is-invalid]="checkControl(false, 2,'desc')"
                [class.is-valid]="checkControl(true, 2,'desc')">
              </textarea>

              <span class="text-gray">
                {{'LEADS.COMPLAINT.INFO.DESC' | translate}}
              </span>
            </div>

          </div>
          <!--end: Form Wizard Step 2-->

          <!--begin: Form Wizard Step 3-->
          <div data-wizard-type="step-content" [formGroup]="steps[3]" >

            <div class="form-group mb-5">
              <p class="mb-0 font-size-h6 lh-1.4"><strong class="text-primary">{{ lead.first_name }} {{ lead.last_name }}</strong> - Lead {{ lead.category.name }} ID <strong>#{{ lead.id }}</strong></p>
              <p class="font-size-h6 mb-5"><strong>{{ lead.location.postalCode }} {{ lead.location.city }}</strong> ({{ lead.location.province }})</p>

              <h4 clss="mb-3">{{'LEADS.COMPLAINT.SUMMARY' | translate}}</h4>
              <ul>
                <li><strong>{{'LEADS.COMPLAINT.reason' | translate}}:</strong> {{'LEADS.COMPLAINT.OPTIONS.'+steps[2].controls['reason'].value | translate}}</li>
                <li *ngIf="steps[2].controls['reason'].value == 'OTHER'"><strong>{{'LEADS.COMPLAINT.desc' | translate}}:</strong> {{steps[2].controls['desc'].value}}</li>
                <li>
                  <strong>{{'LEADS.COMPLAINT.TARGET' | translate}}:</strong>

                  <ul>
                    <ng-container *ngFor="let ic of lead.bought_for; let i=index">
                      <li *ngIf="selectedAmounts[i]" class="pl-2" >
                        <i class="ki ki-long-arrow-next icon-xs"></i>
                        <span class="pl-2" [ngClass]="{ 'diss': (balance <= 0 && !selectedAmounts[i]) }">
                          <strong *ngIf="ic?.short_name; else exclusive">{{ ic.short_name }}</strong> 
                          (<strong class="highlight">
                            <span [ngClass]="{ 'diss': ((ic.price != selectedAmounts[i]) && selectedAmounts[i]) }">{{ic.price}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                            <span *ngIf="(ic.price != selectedAmounts[i]) && selectedAmounts[i]"> {{selectedAmounts[i]}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                          </strong>) - {{ 'LEADS.COMPLAINT.PURCHASE_DATE' | translate }} <strong>{{ic.created_at}}</strong>
                        </span>
                      </li>
                    </ng-container>
                  </ul>
                  
                </li>
              </ul>
            </div>

            <div class="form-group mb-5">
              <h4 class="mb-3">{{'LEADS.COMPLAINT.agreement' | translate}}</h4>

              <label class="mb-0 d-flex align-items-start cursor-pointer">
                <input type="checkbox" formControlName="agreement" name="agreement" class="checkbox-input mr-2 hue"/>
                <p class="lh-1.4 mb-0">{{'LEADS.COMPLAINT.agreement_value' | translate}}</p>
              </label>

              <ng-container *ngIf="steps[3].controls['agreement'].errors && steps[3].controls['agreement'].touched">
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block text-danger">
                    {{ 'LEADS.COMPLAINT.AGREEMENT_REQUIRED' | translate }}
                  </div>
                </div>
              </ng-container>

              <p class="mt-3 text-gray">
                {{'LEADS.COMPLAINT.INFO.AGREEMENT' | translate}}
              </p>
            </div>

          </div>
          <!--end: Form Wizard Step 3-->

          <!--begin: Form Actions -->
          <div class="d-flex justify-content-between border-top pt-5">
            <div class="mr-2">
              <div (click)="prevStep()" data-wizard-type="action-prev"
                class="btn btn-outline-primary font-weight-bold text-uppercase" >
              {{'GLOBAL.PREV_STEP' | translate}}
              </div>
            </div>
            <div>
              <div (click)="onSubmit();" data-wizard-type="action-submit"
                class="btn btn-primary font-weight-bold text-uppercase" >
                <div *ngIf="processing then spinner else textBlock"></div>
                <ng-template #spinner>
                  <span style="left:-5px" class="spinner spinner-secondary m-3"></span>
                </ng-template>
                <ng-template #textBlock>{{'LEADS.COMPLAINT.SUBMIT_COMPLAINT' | translate}}</ng-template>
              </div>

              <div (click)="nextStep()" data-wizard-type="action-next"
                class="btn btn-primary font-weight-bold text-uppercase" >
              {{'GLOBAL.NEXT_STEP' | translate}}
              </div>
            </div>
          </div>
          <!--end: Form Actions -->
        </form>
        <!--end: Form Wizard Form-->
      </div>
    </div>
  
  </div>

  <div class="card border-0" *ngIf="lead?.complaint?.id">
    <div class="form">

      <div class="form-group m-5 mb-10">             
        <p class="mb-0 font-size-h6 lh-1.4">
          <span *ngIf="!lead?.refunded"><strong class="text-primary">{{ lead?.first_name }} {{ lead?.last_name }}</strong> - </span>
          Lead {{ lead?.category?.name }} ID <strong>#{{ lead?.id }}</strong></p>
        <p class="font-size-h6 mb-5"><strong>{{ lead?.location?.postalCode }} {{ lead?.location?.city }}</strong> ({{ lead?.location?.province }})</p>

        <h4 class="mb-3">{{'LEADS.COMPLAINT.SUMMARY' | translate}}</h4>
        <ul class="mb-5">
          <li><strong>{{ 'LEADS.COMPLAINT.SUBMISSION_DATE' | translate }}: <span class="text-primary">{{lead?.complaint?.date}}</span></strong></li>
          <li><strong>{{ 'LEADS.COMPLAINT.reason' | translate }}:</strong>
            {{ getTranslatedReason(lead?.complaint?.reason) }}
          </li>
          <li *ngIf="lead?.complaint?.desc"><strong>{{ 'LEADS.COMPLAINT.desc' | translate }}:</strong> {{lead.complaint.desc}}</li>
          
          <li>
            <strong>{{ 'LEADS.COMPLAINT.TARGET' | translate }}:</strong>
            <ul>
              <ng-container *ngFor="let purchase of lead?.complaint?.purchases; let i=index">
                <li>
                  <i class="ki ki-long-arrow-next icon-xs"></i>
                  <span class="pl-2">
                    <strong *ngIf="purchase.label">{{ purchase.label }}</strong>
                    (<strong class="highlight">
                      <span [ngClass]="{ 'diss': reducedPurchases[i] }">{{purchase.price}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                      <span *ngIf="reducedPurchases[i]"> {{purchase.refund}} {{ 'LEADS.COMPLAINT.POINTS_SHORT' | translate }}</span>
                    </strong>) - {{ 'LEADS.COMPLAINT.PURCHASE_DATE' | translate }} <strong>{{purchase?.created_at}}</strong>
                  </span>
                </li>  
              </ng-container>
            </ul>
          </li>

          <div class="alert alert-light mt-2" role="alert" *ngIf="reduced">
            <strong>{{ 'LEADS.COMPLAINT.HINT' | translate }}</strong>: {{ 'LEADS.COMPLAINT.REDUCED_REFUND_STATUS' | translate }}
          </div>     
        </ul>

        <div class="mb-5">
          <h4 class="mb-0">{{'LEADS.COMPLAINT.STATUS' | translate}}: 
            <strong 
              [ngClass]="statusClass">
              {{ ('LEADS.COMPLAINT.STATUSES.' + lead?.complaint?.status) | translate }}</strong>
          </h4>
          <p *ngIf="lead?.complaint?.resolvedAt" class="opacity-90">{{ 'LEADS.COMPLAINT.RESOLVED_DATE' | translate }}: <strong>{{lead.complaint.resolvedAt}}</strong></p>
        </div>

        <h4 *ngIf="lead?.complaint?.comment" >{{'LEADS.COMPLAINT.COMMENT' | translate}}</h4>
        <span class="opacity-90">{{ lead?.complaint?.comment }}</span>
      </div>

    </div>
  </div>

  <div class="card border-0" *ngIf="complete">
    <div class="form">

      <div class="form-group m-5 mb-10">
        <p *ngIf="!reduced">{{ 'LEADS.COMPLAINT.COMPLAINT_RECIEVED' | translate }}, <strong>{{ 'LEADS.COMPLAINT.POINT_REDUCTION' | translate }} <span class="text-primary">{{refund}} {{ 'LEADS.COMPLAINT.POINTS' | translate }}</span></strong>, {{ 'LEADS.COMPLAINT.POINT_EXPLANATION' | translate }}.</p>

        <p *ngIf="reduced">{{ 'LEADS.COMPLAINT.COMPLAINT_RECIEVED' | translate }}, <strong>{{ 'LEADS.COMPLAINT.LESS_POINT_REDUCTION' | translate }}</strong>, {{ 'LEADS.COMPLAINT.LESS_POINT_EXPLANATION' | translate }} <strong class="text-primary">{{refund}} {{ 'LEADS.COMPLAINT.POINTS' | translate }}</strong>.</p>

        <p>{{ 'LEADS.COMPLAINT.ACCORDING_TO_REGULATIONS' | translate }} <strong>14 {{ 'LEADS.COMPLAINT.DAYS' | translate }}</strong> {{ 'LEADS.COMPLAINT.BUT_USUALLY' | translate }} <strong>{{ 'LEADS.COMPLAINT.FEWER_DAYS' | translate }}</strong> {{ 'LEADS.COMPLAINT.FROM_FILING_DATE' | translate }}.</p>
        <p>{{ 'LEADS.COMPLAINT.POTENTIAL_OUTCOMES' | translate }}:</p>
        <ul>
          <li><strong>{{ 'LEADS.COMPLAINT.APPROVED' | translate }}</strong> - {{ 'LEADS.COMPLAINT.APPROVED_DESC' | translate }}.</li>
          <li><strong>{{ 'LEADS.COMPLAINT.REJECTED' | translate }}</strong> - {{ 'LEADS.COMPLAINT.REJECTED_DESC' | translate }}.</li>
        </ul>
        <p>{{ 'LEADS.COMPLAINT.IN_CASE_QUESTIONS' | translate }} <a href="mailto:obsluga@leadguru.pl">obsluga@leadguru.pl</a></p> 
      </div>

    </div>
  </div>

  <!--end: Wizard -->
</div>
