import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ProductBaseComponent } from '../product-base.component';
import { ProductComponentInterface } from '../product-component-interface';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import { IProductsService } from '../../_services/products-service-interface';
import { BasicProductDto } from '../../_dto/basic-product-dto';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-life-product',
  templateUrl: './life.component.html',
  styleUrls: ['./life.component.scss']
})
export class LifeComponent extends ProductBaseComponent implements ProductComponentInterface, OnDestroy {

  constructor(
    protected productsService: IProductsService,
    protected fb: FormBuilder,
    protected message: MessageService,
    protected changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    protected router: Router
  ) {
    super(productsService, fb, message, router);
    this.type = 'life';
  }

  prepareGroup(){
    this.changeDetectorRef.detectChanges();
  }

  getFormValues() {
    return this.existingData;
  }

  async onSubmit(){
    var selectedOne = false;
    this.existingData.insuranceCompanies.forEach(element => {
      if(element.selected){
        selectedOne = true;
      }
    });
    if(selectedOne){
      this.submit();
    }else{
      this.message.error(await this.translate.get('PRODUCTS.LIFE.SELECT_AT_LEAST_ONE').toPromise());
    }
  }

}
