import { Observable } from "rxjs";
import { ApiResponse } from "src/app/_metronic/shared/models/api-response";
import { ProductDto } from "../_dto/product-dto";

export abstract class IProductsService {

  abstract getProductData(type: string): Observable<ApiResponse<ProductDto>>;

  abstract saveProductData(data: ProductDto): Observable<ApiResponse>;

}
