import { BasicComponent } from "src/app/modules/products/_components/basic/basic.component";
import { LifeComponent } from "src/app/modules/products/_components/life/life.component";


export const ProductsConfig = {
  availableProducts: [
    {
      type: 'basic',
      icon: 'life.jpg',
      name: {
        pl: 'Dane podstawowe',
        en: 'Basic data',
      },
      nameKey: 'BASIC',
      component: BasicComponent
    },
    {
      type: 'life',
      icon: 'life.jpg',
      name: {
        en: 'Life',
        pl: 'Życie'
      },
      nameKey: 'LIFE',
      component: LifeComponent
    }
  ]
}
