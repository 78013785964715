import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-age-filter',
  styleUrls: ['./filters.component.scss'],
  template: `
    <small class="form-text text-muted mb-1">
      <i class="fas fa-exclamation-circle icon-nm mr-1" 
        [ngbTooltip]="'LEADS.LEAD_LIST.TOOLTIP.AGE' | translate"
        [ngClass]="{ 'active-tooltip': form.controls['ageFrom'].value || form.controls['ageTo'].value }"
        style="cursor: help;" 
        container="body" >
      </i>
      {{ 'LEADS.LEAD_LIST.FILTER_BY.AGE' | translate }}
    </small>
    <div [formGroup]="form" class="w-custom d-inline-block">
      <input type="number" class="form-control" name="ageFrom" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.AGE_FROM' | translate }}" 
        value="" formControlName="ageFrom" [ngClass]="{ 'active-filter': form.controls['ageFrom'].value }" />
    </div>
    <span class="middle-line d-inline-block w-20">-</span>
    <div [formGroup]="form" class="w-custom d-inline-block">
      <input type="number" class="form-control" name="ageTo" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.AGE_TO' | translate }}" 
        value="" formControlName="ageTo" [ngClass]="{ 'active-filter': form.controls['ageTo'].value }" />
    </div>
  `
})
export class AgesFilterComponent extends BaseFilterComponent{

  public static formName = ['ageFrom', 'ageTo'];
  //
  registerForm(){
    this.form.addControl('ageFrom', new FormControl(''));
    this.form.addControl('ageTo', new FormControl(''));

    this.subscriptions.push(
      this.form.controls.ageFrom.valueChanges.subscribe(() =>
        this.filter.emit()
      )
    );

    this.subscriptions.push(
      this.form.controls.ageTo.valueChanges.subscribe(() =>
        this.filter.emit()
      )
    );
  }
  
}