import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MessageService } from "./services/message.service";
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageSelectorComponent } from 'src/app/pages/_layout/components/topbar/language-selector/language-selector.component';
import { ProductsFormComponent } from './components/products-form/products-form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IUserProfileService } from 'src/app/modules/user-profile/_services/user-profile-service-interface';
import { environment } from 'src/environments/environment';
import { UserProfileService } from 'src/app/modules/user-profile/_services/user-profile.service';
import { FakeUserProfileService } from 'src/app/modules/user-profile/_services/fake-user-profile.service';

@NgModule({
  declarations: [
    SnackbarComponent,
    ConfirmationDialogComponent,
    LanguageSelectorComponent,
    ProductsFormComponent
  ],
  imports: [
    NgbDropdownModule,
    CommonModule,
    NgbAccordionModule,
    NgbTooltipModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgbAlertModule,
    NgSelectModule,
    NgbCollapseModule,
    MatDialogModule,
    MatSlideToggleModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LanguageSelectorComponent,
    ProductsFormComponent,
    NgbAccordionModule,
    NgbTooltipModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgSelectModule,
    MatDialogModule,
    TranslateModule,
  ],
  providers: [
    MessageService,
    { provide: IUserProfileService, useClass: environment.production ? UserProfileService : FakeUserProfileService },
  ]
})
export class SharedModule { }
