/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { ApiResponse } from '../../models/api-response';

declare var require: any
var moment = require('moment');

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  search: {},
  grouping: new GroupingState(),
  entityId: undefined,
  synced_at: moment().unix()
};

export abstract class TableService<T> {

  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;

  protected http: HttpClient;

  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  private cacheKey: string;

  // Getters
  get items$(): Observable<T[]> {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get search() {
    return this._tableState$.value.search;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  displayClearBtn(inputs: Array<string> = null){
    if(!inputs){
      return Object.keys(this.filter).some(input => this.filter[input]) || Object.keys(this.search).some(input => this.search[input]);
    }
    return inputs.some(input => this.filter[input] || this.search[input]);
  }

  constructor(http: HttpClient) {
    this.http = http;
  }

  // CREATE
  // server should return the object with ID
  create(item: BaseModel): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(this.API_URL, item).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    this._isLoading$.next(true);
    const url = this.API_URL + '/find';
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getItemById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.get<BaseModel>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: BaseModel): Observable<any> {
    const url = `${this.API_URL}/${item.id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this._tableState$.value.paginator.total = res.total;
          const page = this._tableState$.value.paginator;
          this.patchStateWithoutFetch({
            paginator: page
          });
          // this.patchStateWithoutFetch({
          //   paginator: this._tableState$.value.paginator.recalculatePaginator(
          //     res.total
          //   ),
          // });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          // this.patchStateWithoutFetch({
          //   grouping: this._tableState$.value.grouping.clearRows(itemIds),
          // });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults(key) {
    this.cacheKey = key;
    
    let restoredSession = JSON.parse(localStorage.getItem(this.cacheKey));

    //ignore expired cache - older than 4hrs ago
    restoredSession = (restoredSession && restoredSession.synced_at && moment().diff(moment.unix(restoredSession.synced_at), 'hours') >= 4) ?
      null : restoredSession;

    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(this.loadState(restoredSession));
    this._errorMessage.next('');
  }

  public loadState(state): ITableState{
    const chosenState = state ? state : DEFAULT_STATE;
    this.patchStateWithoutFetch({ filter: chosenState.filter },false);
    this.patchStateWithoutFetch({ sorting: chosenState.sorting },false);
    this.patchStateWithoutFetch({ grouping: new GroupingState() },false);
    this.patchStateWithoutFetch({ searchTerm: chosenState.searchTerm },false);
    const pages = new PaginatorState();
    pages.page = state?.paginator?.page ?? 1; 
    pages.pageSize = state?.paginator?.pageSize ?? 10;
    this.patchStateWithoutFetch({ paginator: pages }, false);
    this.patchStateWithoutFetch({ search: chosenState.search }, false);
    return chosenState;
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.unsubscribe();
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>, saveState = true) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
    if(saveState && this.cacheKey){
      newState.synced_at = moment().unix();
      localStorage.setItem(this.cacheKey, JSON.stringify(newState));
    }
  }

  private unsubscribe() {
    this._subscriptions.forEach(sb => sb.unsubscribe());
    this._subscriptions = [];
  }

  getFreshEnums(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${this.API_URL}/get-enums`);
  }

  public async cachedEnums(cacheKey){
    let enums = null;
    let version = localStorage.getItem('enumVersion');
    try {
      if(version == null || version == undefined || version == ''){
        throw new Error('No enum version');
      }
      let cache = localStorage.getItem(cacheKey);
      if(cache == null || cache == undefined || cache == ''){
        throw new Error('Empty cache');
      }
      enums = JSON.parse(cache);
      if(enums == null || enums == undefined || enums == ''){
        throw new Error('Error while parsing enum');
      }
      if(enums?.version == null || enums?.version == undefined || enums?.version == '' || enums?.version != version){
        throw new Error('Old enum version');
      }
    }catch(error){
      let newEnums = await this.getFreshEnums().toPromise().then(results => { return results.data });
      newEnums['version'] = version;
      enums = newEnums;
      localStorage.setItem(cacheKey,JSON.stringify(enums));
    }
    return enums;

  }

}
