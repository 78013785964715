import { CategoryDisplayComponent } from "src/app/modules/leads/_components/custom-columns/category.component";
import { BellActionComponent } from "src/app/modules/leads/_components/custom-actions/bell.component";
import { PreviewActionComponent } from "src/app/modules/leads/_components/custom-actions/preview.component";
import { LocationDisplayComponent } from "src/app/modules/leads/_components/custom-columns/location.component";
import { StatusDisplayComponent } from "src/app/modules/leads/_components/custom-columns/status.component";
import { PriceDisplayComponent } from "src/app/modules/leads/_components/custom-columns/price.component";
import { AddedAtDisplayComponent } from "src/app/modules/leads/_components/custom-columns/added-at.component";
import { ExamplePurchaseExtensionComponent } from "../app/modules/leads/_components/example-purchase-extension/example-purchase-extension.component";
import { LeadsConfig as Config } from "../app/modules/leads/leads-config";
import { SearchTermFilterComponent } from "src/app/modules/leads/_components/custom-filters/search-term.component";
import { CategoriesFilterComponent } from "src/app/modules/leads/_components/custom-filters/categories.component";
import { ProvincesFilterComponent } from "src/app/modules/leads/_components/custom-filters/provinces.component";
import { LocationsFilterComponent } from "src/app/modules/leads/_components/custom-filters/locations.component";
import { PricesFilterComponent } from "src/app/modules/leads/_components/custom-filters/prices.component";
import { LeadIdFilterComponent } from "src/app/modules/leads/_components/custom-filters/lead-id.component";
import { DetailsDisplayComponent } from "src/app/modules/leads/_components/custom-columns/details.component";
import { BoughtAtDisplayComponent } from "src/app/modules/leads/_components/custom-columns/bought-at.component";
import { StatusEditDisplayComponent } from "src/app/modules/leads/_components/custom-columns/status-edit.component";
import { NoteActionComponent } from "src/app/modules/leads/_components/custom-actions/note.component";
import { BuyActionComponent } from "../app/modules/leads/_components/custom-actions/buy.component";
import { SourceDisplayComponent } from "src/app/modules/leads/_components/custom-columns/source.component";
import { StatesFilterComponent } from "src/app/modules/leads/_components/custom-filters/states.component";
import { SourcesFilterComponent } from "src/app/modules/leads/_components/custom-filters/sources.component";
import { AgesFilterComponent } from "src/app/modules/leads/_components/custom-filters/ages.component";
import { ServiceFilterComponent } from "src/app/modules/leads/_components/custom-filters/service.component";
import {RefundStatusFilterComponent} from "../app/modules/leads/_components/custom-filters/refund-status.component";

export const LeadsConfig: Config = {
  list: {
    filters: [
      CategoriesFilterComponent,
      PricesFilterComponent,
      LeadIdFilterComponent,
      ProvincesFilterComponent,
      //SourcesFilterComponent,
      RefundStatusFilterComponent,
      StatesFilterComponent,
      AgesFilterComponent,
      SearchTermFilterComponent,
    ],
    showClearFilters: true,
    columns: [
      {
        name: 'id',
        label: 'ID',
        sortable: true,
        hideLabelOnMobile: true
      },
      {
        name: 'category',
        label: 'CATEGORY',
        sortable: true,
        component: CategoryDisplayComponent,
        hideLabelOnMobile: true
      },
      {
        name: 'location',
        label: 'LOCALISATION',
        sortable: true,
        component: LocationDisplayComponent
      },
      {
        name: 'created_at',
        label: 'ADDED_AT',
        sortable: true,
        component: AddedAtDisplayComponent
      },
      {
        name: 'status',
        label: 'STATUS',
        sortable: true,
        component: StatusDisplayComponent
      },
      {
        name: 'price',
        label: 'PRICE',
        sortable: true,
        component: PriceDisplayComponent
      },
      {
        name: 'source',
        label: 'SOURCE',
        sortable: true,
        component: SourceDisplayComponent,
      }
    ],
    actions: [
      BellActionComponent,
      PreviewActionComponent,
      BuyActionComponent,
    ]
  },
  widget: {
    filters: [],
    showClearFilters: false,
    columns: [
      {
        name: 'id',
        label: 'ID',
        sortable: true
      },
      {
        name: 'category',
        label: 'CATEGORY',
        sortable: true,
        component: CategoryDisplayComponent
      },
      {
        name: 'location',
        label: 'LOCALISATION',
        sortable: true,
        component: LocationDisplayComponent
      },
      {
        name: 'created_at',
        label: 'ADDED_AT',
        sortable: true,
        component: AddedAtDisplayComponent
      },
      {
        name: 'status',
        label: 'STATUS',
        sortable: true,
        component: StatusDisplayComponent
      },
      {
        name: 'price',
        label: 'PRICE',
        sortable: true,
        component: PriceDisplayComponent
      },
      {
        name: 'source',
        label: 'SOURCE',
        sortable: false,
        component: SourceDisplayComponent,
      }
    ],
    actions: [
      BellActionComponent,
      PreviewActionComponent,
      BuyActionComponent,
    ]
  },
  boughtWidget: {
    filters: [],
    showClearFilters: false,
    columns: [
      {
        name: 'id',
        label: 'ID',
        sortable: true
      },
      {
        name: 'category',
        label: 'CATEGORY',
        sortable: true,
        component: CategoryDisplayComponent
      },
      {
        name: 'details',
        label: 'DETAILS',
        sortable: true,
        component: DetailsDisplayComponent
      },
      {
        name: 'location',
        label: 'LOCALISATION',
        sortable: true,
        component: LocationDisplayComponent
      },
      {
        name: 'bought_at',
        label: 'BOUGHT_AT',
        sortable: true,
        component: BoughtAtDisplayComponent
      },
      {
        name: 'price',
        label: 'PRICE',
        sortable: true,
        component: PriceDisplayComponent
      },
      // {
      //   name: 'source',
      //   label: 'SOURCE',
      //   sortable: false,
      //   component: SourceDisplayComponent,
      // }
    ],
    actions: [
      NoteActionComponent,
      BellActionComponent,
      PreviewActionComponent,
      BuyActionComponent,
    ]
  },
  boughtList: {
    filters: [
      CategoriesFilterComponent,
      PricesFilterComponent,
      LeadIdFilterComponent,
      ProvincesFilterComponent,
      //SourcesFilterComponent,
      RefundStatusFilterComponent,
      ServiceFilterComponent,
      AgesFilterComponent,
      SearchTermFilterComponent,
    ],
    showClearFilters: true,
    columns: [
      {
        name: 'id',
        label: 'ID',
        sortable: true,
        hideLabelOnMobile: true
      },
      {
        name: 'category',
        label: 'CATEGORY',
        sortable: true,
        component: CategoryDisplayComponent,
        hideLabelOnMobile: true
      },
      {
        name: 'details',
        label: 'DETAILS',
        sortable: true,
        component: DetailsDisplayComponent
      },
      {
        name: 'location',
        label: 'LOCALISATION',
        sortable: true,
        component: LocationDisplayComponent
      },
      {
        name: 'bought_at',
        label: 'BOUGHT_AT',
        sortable: true,
        component: BoughtAtDisplayComponent
      },
      {
        name: 'price',
        label: 'PRICE',
        sortable: true,
        component: PriceDisplayComponent
      },
      {
        name: 'status',
        label: 'STATE',
        sortable: true,
        component: StatusEditDisplayComponent,
        hideLabelOnMobile: true
      },
      // {
      //   name: 'source',
      //   label: 'SOURCE',
      //   sortable: false,
      //   component: SourceDisplayComponent,
      // }
    ],
    actions: [
      NoteActionComponent,
      BellActionComponent,
      PreviewActionComponent,
      BuyActionComponent,
    ]
  },
  purchaseExtension: null,
}
