import { AuthModel } from "../../modules/auth/_models/auth.model";
import { User } from "../../modules/auth";

const expirationDate = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);

const users: User[] = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'admin@demo.com',
    phone: '456669067890',
    avatar: './assets/media/users/default.jpg',
    points: 1350,
    address: {
      street: 'Kazimierza Pułaskiego',
      buildingNumber: '11',
      apartmentNumber: '',
      postalCode: '63-400',
      city: 'Ostrów Wielkopolski',
    },
    invoiceInformation: {
      companyName: 'Test Company',
      tin: '66222444555',
      address: {
        street: 'Kazimierza Pułaskiego',
        buildingNumber: '11',
        apartmentNumber: '',
        postalCode: '63-400',
        city: 'Ostrów Wielkopolski',
      },
    },
    notificationsSettings: {
      sms: {
        new_lead: true,
        marketing: false,
      },
      mail:{
        new_lead: false,
        marketing: true,
      }
    }
  },
  {
    id: 2,
    firstName: 'Kenny',
    lastName: 'Rossmore',
    email: 'user@demo.com',
    phone: '456669067890',
    avatar: './assets/media/users/default.jpg',
    points: 650,
    address: {
      street: 'Plac 23 Stycznia',
      buildingNumber: '30',
      apartmentNumber: '4',
      postalCode: '63-400',
      city: 'Ostrów Wielkopolski',
    },
    invoiceInformation: {
      companyName: 'Company Testible',
      tin: '66222444555',
      address: {
        street: 'Plac 23 Stycznia',
        buildingNumber: '30',
        apartmentNumber: '4',
        postalCode: '63-400',
        city: 'Ostrów Wielkopolski',
      },
    },
    notificationsSettings: {
      sms: {
        new_lead: true,
        marketing: false,
      },
      mail:{
        new_lead: false,
        marketing: true,
      }
    }
  }
].map((user) => {
  const usr = new User();
  usr.setUser(user);
  return usr;
})

export class UsersTable {
  public static users: User[] = users;
}
