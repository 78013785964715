import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProductComponentInterface} from './product-component-interface';
import {finalize, first} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import { IProductsService } from '../_services/products-service-interface';
import { ProductDto } from '../_dto/product-dto';
import { IProduct } from '../_interfaces/IProduct';
import { Router } from '@angular/router';

@Component({
  template: ``
})

export abstract class ProductBaseComponent implements OnInit, OnDestroy, ProductComponentInterface{

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  hasError: boolean;
  existingData: any = {};
  type: string;

  constructor(
    protected productsService: IProductsService,
    protected fb: FormBuilder,
    protected message: MessageService,
    protected router: Router
  ) {

  }

  async ngOnInit() {
    let done = await this.getExistingData();
    this.prepareGroup();
  }

  async getExistingData() {
    return new Promise(resolve => {
    const loadSubscr = this.productsService.getProductData(this.type)
      .pipe(
        //finalize(() => this.authService.isLoadingSubject.next(false))
      )
      .subscribe(
        ({ message, status, data }) => {
          if (!status) {
            this.existingData = data;
          }else{
            this.message.error(message || 'Error');
          }
          resolve(true);
        }
      )
      this.unsubscribe.push(loadSubscr);
    });
  }

  submit() {
    const data = this.getFormValues();

    let dto = new ProductDto();
    dto.type = this.type;
    dto.data = data;

    const submitSubscr = this.productsService.saveProductData(dto)
    .pipe(
      //finalize(() => this.authService.isLoadingSubject.next(false))
    )
    .subscribe(
      ({ message, status }) => {
        if (!status) {
          this.message.success(message);
          this.router.navigate(['/products']);
        }else{
          this.message.error(message || 'Error');
        }
      }
    );

    this.unsubscribe.push(submitSubscr);
  }

  abstract getFormValues(): IProduct;

  abstract prepareGroup();

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
