export class Address {
  street: string;
  buildingNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  county: string;
  province: string;

  constructor() {
    this.street = '';
    this.buildingNumber = '';
    this.apartmentNumber = '';
    this.postalCode = '';
    this.city = '';
    this.county = '';
    this.province = '';
  }

  setAddress(address: any) {
    this.street = address.street || '';
    this.buildingNumber = address.buildingNumber || '';
    this.apartmentNumber = address.apartmentNumber || '';
    this.postalCode = address.postalCode || '';
    this.city = address.city || '';
    this.county = address.county || '';
    this.province = address.province || '';
  }
}
