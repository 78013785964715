import { Provider } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandlingInterceptor } from "./error-handling.interceptor";
import { LocaleInterceptor } from "./locale.interceptor";
import { AuthInterceptor } from "./auth.interceptor";

const AppInterceptors: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

export { AppInterceptors }
