import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeHelper } from 'src/app/helpers/time-helper';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-added-at-display',
  template: `
  <div style="line-height: 14px; display:inline-block;">
    <span *ngIf="diff"><strong>{{diff}}</strong> <br></span>
    <small *ngIf="date">{{date}}</small>
  </div>
  `
})
export class AddedAtDisplayComponent extends BaseDisplayComponent<Lead> implements OnInit{

  public date;
  public diff;

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    public datepipe: DatePipe
  ) { super() }

  async ngOnInit() {
    if(!this.value.created_at){
      this.date = null;
      return;
    }

    try{
      let now = new Date();
      this.date = TimeHelper.parseDate(this.value.created_at);
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((this.date.getTime() - now.getTime()) / oneDay));
      switch (diffDays) {
        case 0:
          this.diff = await this.translate.get('LEADS.LEAD_LIST.DATE.TODAY').toPromise();
          break;

        case 1:
          this.diff = await this.translate.get('LEADS.LEAD_LIST.DATE.YESTERDAY').toPromise();
          break;

        case 2:
          this.diff = await this.translate.get('LEADS.LEAD_LIST.DATE.TWO_DAYS_AGO').toPromise();
          break;

        default:
          this.diff = diffDays + ' ' + await this.translate.get('LEADS.LEAD_LIST.DATE.DAYS_AGO').toPromise();
          break;
      }
      this.date = this.datepipe.transform(this.date, 'Y-MM-dd HH:mm:ss');
    }catch(error){
      this.diff = null;
      this.date = this.value.created_at;
    }
    this.changeDetectorRef.detectChanges();
  }

}