import { Address } from "./address.model";

export class InvoiceInformation {
  isCompany: boolean;
  hideReceiver: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
  address: Address;
  tin: string;
  billingMode: string;
  nextBillingMode: string;
  delayModeChange: boolean;

  constructor() {
    this.isCompany = true;
    this.hideReceiver = false;
    this.firstName = '';
    this.lastName = '';
    this.companyName = '';
    this.tin = '';
    this.address = new Address();
    this.billingMode = '';
    this.nextBillingMode = '';
    this.delayModeChange = false;
  }

  setInvoiceInformation(data: any) {
    this.isCompany = data.isCompany || true;
    this.hideReceiver = data.hideReceiver || false;
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.companyName = data.companyName || '';
    this.tin = data.tin || '';
    this.billingMode = data.billingMode || '';
    this.nextBillingMode = data.nextBillingMode || '';
    this.delayModeChange = data.delayModeChange || false;

    this.address.setAddress(data.address || {});
  }
}
