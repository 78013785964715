import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirm-agreement',
  templateUrl: './confirm-agreement.component.html',
  styleUrls: ['./confirm-agreement.component.scss']
})
export class ConfirmAgreementComponent {

  @Input() content: string;
  @Input() date: string;
  @Output() clickedConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private modal: NgbActiveModal
  ) {

  }

  confirm(){
    this.clickedConfirm.emit(true);
  }

}

 