import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ConfirmPasswordValidator {
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */
  static MatchPassword(control: AbstractControl) {
    if(!control || !control.get('cPassword')){
      return null;
    }
    const password = control.get('password').value;

    const confirmPassword = control.get('cPassword').value;

    if (password !== confirmPassword) {
      control.get('cPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }
}

export function matchTwoFields(fieldOne: string, fieldTwo: string): ValidatorFn {
 
  return (control: AbstractControl): ValidationErrors | null => {
 
    if(!control || !control.get(fieldTwo)){
      return null;
    }
    const password = control.get(fieldOne).value;

    const confirmPassword = control.get(fieldTwo).value;

    if (password !== confirmPassword) {
      control.get(fieldTwo).setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    
  }
 
}
