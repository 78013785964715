<div class="font-size-lg snackbar d-flex align-items-center">
  <div>
    <span *ngIf="data.type === 'success'" class="fas fa-check-circle text-success font-size-lg"></span>
    <span *ngIf="data.type === 'error'" class="fas fa-exclamation-circle text-danger font-size-lg"></span>
    <span *ngIf="data.type === 'warning'" class="fas fa-exclamation-triangle text-warning font-size-lg"></span>
    <span *ngIf="data.type === 'info'" class="fas fa-info-circle text-primary font-size-lg"></span>
  </div>

  <span class="ml-4 flex-grow-1" [innerHtml]="data.message"></span>
</div>
