import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";

export class TranslateUtils {
  static reload(translate: TranslateService) {
    const currentLang = localStorage.getItem('language') || environment.defaultLang;
    translate.currentLang = '';
    translate.use(currentLang);
  }
}
