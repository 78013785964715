export class MathUtils {
  static getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  static getRandomLatitude(): number {
    return parseFloat(`${MathUtils.getRandomInt(53, 49)}.${MathUtils.getRandomInt(10000000, 99999999)}`);
  }

  static getRandomLongitude(): number {
    return parseFloat(`${MathUtils.getRandomInt(13, 22)}.${MathUtils.getRandomInt(10000000, 99999999)}`);
  }
}
