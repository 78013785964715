import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-status-display',
  template: `
  <p class="btn status {{value?.status?.class}} active btn-text cursor-default btn-sm mb-0" [ngClass]="{'' : mobile}">
    {{ value?.status?.name }}
  </p>
  `
})
export class StatusDisplayComponent extends BaseDisplayComponent<Lead>{

}