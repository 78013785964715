import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/_services/auth.guard';
import { GuestGuard } from './modules/auth/_services/guest.guard';
import {VerifyHashComponent} from "./modules/verify/verify-hash.component";

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'disable-mailing',
    loadChildren: () =>
      import('./modules/disable-mailing/disable-mailing.module').then((m) => m.DisableMailingModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },

  { path: 'verify-hash/:hash', component: VerifyHashComponent }, // Ścieżka dla VerifyHashComponent
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
