import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TimeHelper } from 'src/app/helpers/time-helper';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-bought-at-display',
  template: `
  <div style="line-height: 14px; display:inline-block;">
    <small><strong>{{purchasedAt || '-'}}</strong></small>
    <br>
    <small>{{createdAt || '-' }}</small>
  </div>
  `
})
export class BoughtAtDisplayComponent extends BaseDisplayComponent<Lead> implements OnInit{
  
  public purchasedAt;
  public createdAt;

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    public datepipe: DatePipe
  ) { super() }

  ngOnInit() {
    try{
      this.purchasedAt = this.datepipe.transform(TimeHelper.parseDate(this.value.purchased_at), 'Y-MM-dd HH:mm:ss');
    }catch(error){
      this.purchasedAt = this.value.purchased_at;
    }

    try{
      this.createdAt = this.datepipe.transform(TimeHelper.parseDate(this.value.created_at), 'Y-MM-dd HH:mm:ss');
    }catch(error){
      this.createdAt = this.value.created_at;
    }

    this.changeDetectorRef.detectChanges();
  }
}