import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiredWhen(requireField:string, whenField: string, hasValue): ValidatorFn {
 
    return (control: AbstractControl): ValidationErrors | null => {
      if(!control || !control.get(whenField) || !control.get(requireField)){
        return null;
      }

      if (control.get(whenField).value == hasValue) {
        if(control.get(requireField).value == null || control.get(requireField).value == ""){
          control.get(requireField).setErrors({ "required": true });
          return { "required": true };
        }
      }
      
      return null;
    }
   
}