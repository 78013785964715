export class MessagesDto {
  unread: number = 0;
  total: number = 0;
  list: MessageDto[] = [];

  setMessages(messages: any) {
    this.unread = messages.unread || '';
    this.total = messages.total || '';
    this.list = JSON.parse(JSON.stringify(messages.list || []));
  }
}

export class MessageDto{
  id: number;
  title: string = null;
  date: string|Date = null;
  icon: string = null;
  content: string = null;
  read: boolean = false;
}

