import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-search-term-filter',
  template: `
  <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.AMOUNT' | translate }}</small>
    <div [formGroup]="form" class="w-custom d-inline-block">
      <input type="number" class="form-control" name="amountFrom" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.AMOUNT_FROM' | translate }}" value=""
        formControlName="amountFrom" />
    </div>
    <span class="middle-line d-inline-block w-20">-</span>
    <div [formGroup]="form" class="w-custom d-inline-block">
      <input type="number" class="form-control" name="amountTo" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.AMOUNT_TO' | translate }}" value=""
        formControlName="amountTo" />
    </div>
  `
})
export class PricesFilterComponent extends BaseFilterComponent{

  public static formName = ['amountFrom', 'amountTo'];

  registerForm(){
    this.form.addControl('amountFrom', new FormControl(''));
    this.form.addControl('amountTo', new FormControl(''));

    this.subscriptions.push(
      this.form.controls.amountFrom.valueChanges.subscribe(() =>
        this.filter.emit()
      )
    );

    this.subscriptions.push(
      this.form.controls.amountTo.valueChanges.subscribe(() =>
        this.filter.emit()
      )
    );
  }
  
}