import { Validators } from "@angular/forms";
import { Address } from "../../../modules/auth/_models/address.model";

export class AddressUtil {
  static getFormControls(address: Address) {
    return {
      street: [address.street, Validators.required],
      buildingNumber: [address.buildingNumber, Validators.compose([Validators.required, Validators.maxLength(5)])],
      apartmentNumber: [address.apartmentNumber, Validators.maxLength(5)],
      postalCode: [address.postalCode, Validators.compose([Validators.required, Validators.pattern(/^\d{2}-\d{3}$/)])],
      city: [address.city, Validators.required],
    }
  }

  static getValidatorlessFormControls(address: Address) {
    return {
      street: [address.street],
      buildingNumber: [address.buildingNumber],
      apartmentNumber: [address.apartmentNumber],
      postalCode: [address.postalCode],
      city: [address.city],
    }
  }

  static getValidators() {
    var validators = [];
    validators['street'] = Validators.required;
    validators['buildingNumber'] = Validators.compose([Validators.required, Validators.maxLength(5)]);
    validators['apartmentNumber'] = Validators.maxLength(5);
    validators['postalCode'] = Validators.compose([Validators.required, Validators.pattern(/^\d{2}-\d{3}$/)]);
    validators['city'] = Validators.required;
    return validators;
  }

}
