import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-service-filter',
  template: `
  <div [formGroup]="form">
    <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.SERVICE' | translate }}</small>
    <select class="form-control" name="service" formControlName="service">
      <option value="" [ngValue]="null">{{ 'GLOBAL.ALL' | translate }}</option>
      <option value="{{service.value}}" *ngFor="let service of enums.services">{{service.label}}</option>
    </select>
  </div>
  `
})
export class ServiceFilterComponent extends BaseFilterComponent{

  public static formName = ['service'];

  registerForm(){
    this.form.addControl('service', new FormControl(null));

    this.subscriptions.push(
      this.form.controls.service.valueChanges.subscribe(() =>{
        this.filter.emit();
      }
    )
    );
  }
  
}