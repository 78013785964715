import { InjectionToken } from '@angular/core';

export const USER_DROPDOWN_GLOBAL_CONFIG =
  new InjectionToken<UserDropdownItem[]>('user-dropdown-global-config');

export type IconColor = 'text-primary' | 'text-secondary' | 'text-success' | 'text-danger' | 'text-warning' | 'text-info' | 'text-light' | 'text-dark' | 'text-muted';
// If you want to force only bootstrap color classes set color type to one above

export interface UserDropdownItem {
  translation: string;
  icon?: string;
  color?: string;
  url?: string;
  canBeInactive?: boolean;
}
