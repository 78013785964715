<h4 class="m-10 font-weight-bold text-dark">
  {{'PRODUCTS.LIFE.INSURANCE_COMPANIES' | translate}}
</h4>

<div class="form-group row">
  <div class="col-6" *ngFor="let company of existingData.insuranceCompanies">
    <label class="col-12 col-form-label">{{company.label}} 
      <mat-slide-toggle [(ngModel)]="company.selected" [color]="'warn'"></mat-slide-toggle>
    </label>
  </div>
</div>

<div *ngIf="existingData?.insuranceCompanies" (click)="onSubmit()" class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" >
{{'PRODUCTS.SUBMIT' | translate}}
</div>