/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { IAuthService } from '../modules/auth';
import { MessageService } from '../_metronic/shared/services/message.service';
import { Router } from '@angular/router';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginResponse } from '../modules/auth/_responses/login.response';
import { ApiResponse } from '../_metronic/shared/models/api-response';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authService: IAuthService;
  private messageService: MessageService;
  private router: Router;
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(injector: Injector) {
    this.authService = injector.get(IAuthService);
    this.messageService = injector.get(MessageService);
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;

    if(!request.url.includes(environment.backendUrl) && environment.basicAuth){
      headers = headers.append('Authorization', `Basic ${btoa(environment.basicAuth)}`);
    }else if(!this.isAuthRequest(request)) {
      headers = headers.append('Authorization', `Bearer ${this.authService.getAuthFromLocalStorage()?.accessToken}`);
    }

    return next.handle(request.clone({ headers }))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && !request.url.includes('auth')) {
            this.authService.hardLogout();
          }
          return throwError(error);
        })
      );
  }

  private isAuthRequest(request: HttpRequest<any>): boolean {
    return request.url.includes('auth') &&
      !request.url.includes('logout') &&
      !request.url.includes('user') &&
      !request.url.includes('snapshot') &&
      !request.url.includes('flag') &&
      !request.url.includes('activation-back') &&
      !request.url.includes('lang');
  }

  private tryToRefresh(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(this.authService.getAuthFromLocalStorage()?.accessToken)
        .pipe(
          switchMap((response: ApiResponse<LoginResponse>) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(response.data.accessToken);

            this.authService.setAuthToLocalStorage(response.data);

            return next.handle(this.addToken(req, response.data.accessToken));
          }),
          catchError(error => {
            this.isRefreshing = false;
            this.messageService.error('Błąd autoryzacji. Prosimy zalogować się ponownie.');
            this.router.navigate([ '/auth/login' ]);
            return of(error);
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addToken(req, token)))
      );
    }
  }

  private addToken(req: HttpRequest<any>, token: unknown): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private logout(error: HttpErrorResponse) {
    if (error.status !== 401) {
      return;
    }

    this.authService.logout();
  }
}
