

export class ProfileNotificationsDto {
  sms: NotificationConfigDto = new NotificationConfigDto;
  email: NotificationConfigDto = new NotificationConfigDto;

  setProfileNotifications(notifications: any) {
    this.sms = notifications.sms || '';
    this.email = notifications.mail || '';
  }
}

class NotificationConfigDto {
  new_lead: boolean = false;
  marketing: boolean = false;

  setNotificationConfig(config: any) {
    this.new_lead = config.new_lead || false;
    this.marketing = config.marketing || false;
  }
}

