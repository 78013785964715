import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-source-display',
  template: `
    <strong>{{value.source ? value.source : '-'}}</strong>
    <div *ngIf="value?.tags[0]">
      <ng-template #tagsTooltip>
        <span *ngFor="let tag of value.tags.slice(1)" class="d-block mb-1 badge badge-pill badge-{{tag.class}}">{{tag.name}}</span>
      </ng-template>
      <span class="badge badge-pill badge-{{value.tags[0].class}}">{{value.tags[0].name}}</span>
      <span *ngIf="value?.tags.length > 1" class="ml-1 badge badge-pill badge-info" [ngbTooltip]="tagsTooltip">+{{value.tags.length - 1}}</span>
    </div>
  `
})
export class SourceDisplayComponent extends BaseDisplayComponent<Lead>{

}