import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-search-term-filter',
  template: `
  <div [formGroup]="form">
    <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.REFUND' | translate }}</small>
    <select class="form-control" name="refund" formControlName="refund">
      <option value="" [ngValue]="null">{{ 'GLOBAL.ALL' | translate }}</option>
      <option value="{{refund.value}}" *ngFor="let refund of enums.refunds">{{refund.label}}</option>
    </select>
  </div>
  `
})
export class RefundStatusFilterComponent extends BaseFilterComponent{

  public static formName = ['refund'];

  registerForm(){
    this.form.addControl('refund', new FormControl(null));

    this.subscriptions.push(
      this.form.controls.refund.valueChanges.subscribe(() =>{
        this.filter.emit();
      }
    )
    );
  }

}
