import { AgreementDto } from "src/app/_dto/agreement-dto";
import { ProductsDto } from "src/app/_dto/products-dto";
import { Address } from "../../auth/_models/address.model";
import { IProduct } from "../_interfaces/IProduct";

export class BasicProductDto implements IProduct{

  companyName: string;
  address: Address;
  tin: string;
  nextBillingMode: string;

  useInvoiceAsContact: boolean;
  contactAddress: Address;

  agentId: string;
  workerId: string;

  agreements: AgreementDto[];

  products: ProductsDto[];

  secondaryAgreements: AgreementDto[];

  isCompany: boolean;
  hideReceiver: boolean;
  firstName: string | null;
  lastName: string | null;

  constructor() {
    this.companyName = '';
    this.tin = '';
    this.address = new Address();
    this.nextBillingMode = '';

    this.useInvoiceAsContact = true;
    this.contactAddress = new Address();

    this.agentId = '';
    this.workerId = '';
    this.agreements = [];

    this.products = [];

    this.secondaryAgreements = [];
  }

}
