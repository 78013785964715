import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarData, SnackbarType } from "../models/snackbar-data";
import { SnackbarComponent } from "../components/snackbar/snackbar.component";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  success(message: string) {
    return this.showSnack('success', message);
  }

  error(message: string) {
    return this.showSnack('error', message);
  }

  warning(message: string) {
    return this.showSnack('warning', message);
  }

  info(message: string) {
    return this.showSnack('info', message);
  }

  show(message: string) {
    return this.showSnack(undefined, message);
  }

  confirm(text: string, title = null, confirmButton = null, rejectButton = null, confirmed = () => {}, rejected = () => {}, afterClosed = () => {}) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { text, title, confirmButton, rejectButton, confirmed, rejected },
        minWidth: 400,
        maxWidth: 600
      });

      dialogRef.afterClosed().subscribe(() => afterClosed);
    });
  }

  private showSnack(type: SnackbarType, message: string) {
    const data: SnackbarData = {
      type, message,
    };

    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: 'text-white',
      data
    });
  }
}
