// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postalCode',
})
export class PostalCodePipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    let output = value;

    if(output && typeof output != 'object'){
      output+='';
      if(output.charAt(2) == "-") return output;

      if(output.length === 5){
        return output.substring(0, 2) + '-' + output.substring(2, 5);
      };
        output = output.padStart(5, '0');
        return output.substring(0, 2) + '-' + output.substring(2, 5);
    }
    return output;
  }
}
