import { InjectionToken } from '@angular/core';
import { LanguageObject } from 'src/app/app-config';

export const PRODUCTS_GLOBAL_CONFIG =
  new InjectionToken<ProductsConfig>('products-global-config');

export interface Product {
  type: string,
  icon: string,
  name: LanguageObject,
  nameKey: string,
  component
}

export interface ProductsConfig {
  availableProducts: Product[]
}
