import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UpdateProfileInformationDto } from "../_dto/update-profile-information-dto";
import { Observable } from "rxjs";
import { ApiResponse } from "../../../_metronic/shared/models/api-response";
import { IUserProfileService } from "./user-profile-service-interface";
import { UpdateAddressInformationDto } from "../_dto/update-address-information-dto";
import { UpdateInvoiceInformationDto } from "../_dto/update-invoice-information-dto";
import { ChangePasswordDto } from '../../auth/_dto/change-password-dto';
import { ChangeEmailDto } from '../../auth/_dto/change-email-dto';
import { ProfileNotificationsDto } from '../_dto/notifications-information-dto';
import { AppConfig, APP_CONFIG } from 'src/app/app-config';
import { UpdateProductsDto } from '../_dto/update-products-dto';
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { CategoryConfigDto } from 'src/app/_dto/category-config-dto';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService implements IUserProfileService {

  private readonly url: string;
  private readonly base_url: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient
  ) {
    this.base_url = config.apiUrl;
    this.url = `${config.apiUrl}/user-profile`
  }

  getCategoryConfig(): Observable<ApiResponse<CategoryConfigDto>> {
    return this.http.get<ApiResponse<CategoryConfigDto>>(`${this.url}/products`);
  }

  updateProfileInformation(data: UpdateProfileInformationDto): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/personal-information`, data);
  }

  updateAddressInformation(data: UpdateAddressInformationDto): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/address-information`, data);
  }

  updateInvoiceInformation(data: UpdateInvoiceInformationDto): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/invoice-information`, data);
  }

  updateNotificationsSettings(data: ProfileNotificationsDto, agreements: AgreementDto[]): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/notifications`, {data, agreements});
  }

  changePassword(data: ChangePasswordDto): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/change-password`, data);
  }

  updateProducts(data: UpdateProductsDto): Observable<ApiResponse>{
    return this.http.patch<ApiResponse>(`${this.url}/products`, data);
  }

  changeEmail(data: ChangeEmailDto): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(`${this.url}/change-email`, data);
  }

  disableNewLeadMail(hash: string, user_id: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>( this.base_url + `/disable-mailing/new-lead/${ user_id }/${ hash }`, {});
  }

}
