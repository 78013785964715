export class TimeHelper{
  
    public static parseDate(date){
        var parts = date.split(" ");
        var date = parts[0].split(".");
        var time = parts[1].split(":");
        let then = new Date();
        then.setFullYear(parseInt(date[2]));
        then.setMonth(parseInt(date[1])-1);
        then.setDate(parseInt(date[0]));
        then.setHours(parseInt(time[0]));
        then.setMinutes(parseInt(time[1]));
        then.setSeconds(parseInt(time[2]));
        return then;
    }
    
}