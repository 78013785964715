import { InjectionToken } from '@angular/core';
import { AgreementDto } from 'src/app/_dto/agreement-dto';

export const REGISTRATION_GLOBAL_CONFIG =
  new InjectionToken<RegistrationConfig>('registration-global-config');

export interface AgreementConfig {
  text: string;
  required: boolean;
}

export interface RegistrationConfig {
  agreements: AgreementDto[];
  logo: string;
  agreementLanguageForBe: string;
}
