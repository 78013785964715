import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-category-display',
  template: `
    <a title="{{ 'LEADS.LEAD_LIST.ACTIONS.PREVIEW' | translate }}" class="btn btn-outline-primary btn-hover-primary btn-sm" routerLink="{{ boughtPrefix }}/{{value.id}}">
      {{ 'LEADS.LEAD_LIST.ACTIONS.PREVIEW' | translate }}
    </a>
  `
})
export class PreviewActionComponent extends BaseDisplayComponent<Lead>{
  get boughtPrefix(): string {
    return this.value.bought ? '/bought' : '/leads';
  }
}
