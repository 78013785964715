import { Injectable } from '@angular/core';
import { UpdateProfileInformationDto } from "../_dto/update-profile-information-dto";
import { Observable, of } from "rxjs";
import { ApiResponse } from "../../../_metronic/shared/models/api-response";
import { IUserProfileService } from "./user-profile-service-interface";
import { delay } from "rxjs/operators";
import { UpdateAddressInformationDto } from "../_dto/update-address-information-dto";
import { MathUtils } from "../../../_metronic/core/utils/math.utils";
import { UpdateInvoiceInformationDto } from "../_dto/update-invoice-information-dto";
import { ChangePasswordDto } from '../../auth/_dto/change-password-dto';
import { ChangeEmailDto } from '../../auth/_dto/change-email-dto';
import { ProfileNotificationsDto } from '../_dto/notifications-information-dto';
import { UpdateProductsDto } from '../_dto/update-products-dto';
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { ProductsDto } from 'src/app/_dto/products-dto';
import { CategoryConfigDto } from 'src/app/_dto/category-config-dto';

@Injectable({
  providedIn: 'root'
})
export class FakeUserProfileService extends IUserProfileService {


  getCategoryConfig(): Observable<ApiResponse<CategoryConfigDto>> {
    return of({
      message: 'Informacje o profilu zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  updateProfileInformation(data: UpdateProfileInformationDto): Observable<ApiResponse> {
    return of({
      message: 'Informacje o profilu zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  updateAddressInformation(data: UpdateAddressInformationDto): Observable<ApiResponse> {
    return of({
      message: 'Dane adresowe zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  updateProducts(data: UpdateProductsDto): Observable<ApiResponse> {
    return of({
      message: 'Dane adresowe zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  updateInvoiceInformation(data: UpdateInvoiceInformationDto): Observable<ApiResponse> {
    return of({
      message: 'Dane do faktury zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  updateNotificationsSettings(data: ProfileNotificationsDto, agreements: AgreementDto[]): Observable<ApiResponse> {
    return of({
      message: 'Ustawienia powiadomień zostały zaktualizowane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  changePassword(data: ChangePasswordDto): Observable<ApiResponse> {
    return of({
      message: 'Hasło zostało zmienione.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }


  changeEmail(data: ChangeEmailDto): Observable<ApiResponse> {
    return of({
      message: 'Link do zmiany maila został wysłany na aktualny adres.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  private getRandomResponseDelay(): number {
    return MathUtils.getRandomInt(100, 500);
  }

  disableNewLeadMail(hash: string, user_id: string): Observable<ApiResponse> {
    return of({
      message: 'Powiadomienia zostały wyłączone.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }
}
