<div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h4 class="text-primary mb-0">{{ 'MENU.NEW_AGREEMENT' | translate }}</h4>
    </div>


    <div class="card-body padding">

        <p>{{ 'MENU.NEW_AGREEMENT_PREAMBLE' | translate:{ date: date } }}</p>

        <div class="w-100 p-5 border" style="height: 300px; overflow-y: scroll;" [innerHtml]="content"></div>

    </div>

    <div class="card-footer">
        <button data-cy="agreement_confirm" class="btn btn-primary" (click)="confirm()">{{ 'MENU.UNDERSTAND' | translate }}</button>
    </div>
</div>