import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { MessageService } from "../_metronic/shared/services/message.service";
import { Injectable, Injector } from "@angular/core";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { ApiResponse } from "../_metronic/shared/models/api-response";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private messageService: MessageService;
  private translate: TranslateService;
  private router: Router;

  constructor(private injector: Injector) {
    this.messageService = injector.get(MessageService);
    this.translate = injector.get(TranslateService);
    this.router = injector.get(Router);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        response => {
          if (!(response instanceof ApiResponse) || !response.status) {
            return response;
          }

          throw new Error(response.message);
        },
        async (error: HttpErrorResponse) => {
          const { redirect, message } = await this.processError(error);

          if (message &&  !this.expectsError(req)) {
            this.messageService.error(message);
          }

          if (redirect) {
            await this.router.navigate(['/']);
          }

          return error;
        }
      )
    );
  }

  private expectsError(request: HttpRequest<any>): boolean {
    return request.url.includes('online') || !request.url.includes('snapshot');
  }

  private processError(error: HttpErrorResponse) {
    let redirect = false;
    let message: string = error.message;

    switch (error.status) {
      case 401:
        message = null;
        break;
      case 403:
        redirect = true;
        break;
      default:
        message = message || this.translate.instant('ERRORS.COMMON');
        break;
    }

    return { redirect, message };
  }
}
