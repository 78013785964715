import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogData } from "../../models/confirm-dialog-data";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

  confirmed() {
    if (typeof this.data.confirmed === 'function') {
      this.data.confirmed();
    }
  }

  rejected() {
    if (typeof this.data.rejected === 'function') {
      this.data.rejected();
    }
  }

  ngOnInit(): void {
  }

}
