import { Component, OnInit } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-category-display',
  template: `
    <ng-template #tooltipContent>
      <span class="text-left">
        <strong>{{ 'LEADS.LEAD_LIST.ACTIONS.LAST_NOTE' | translate }}</strong>
        <hr />
        <p>{{ createdAt || '' }}</p>
        <p>{{ content || '-' }}</p>
      </span>
    </ng-template>
    <i
      class="btn btn-icon btn-light btn-hover-primary btn-sm"
      [ngClass]="{ disabled: !content }"
      title="{{ 'LEADS.LEAD_LIST.ACTIONS.LAST_NOTE' | translate }}"
      [ngbTooltip]="content ? tooltipContent : ''"
      tooltipClass="my-custom-class"
      placement="{{ mobile ? 'auto' : 'left' }}"
    >
      <span
        [inlineSVG]="'./assets/media/svg/icons/General/Clipboard.svg'"
        cacheSVG="true"
        class="svg-icon svg-icon-md"
        [ngClass]="content ? 'svg-icon-primary' : ''"
      >
      </span>
    </i>
  `,
})
export class NoteActionComponent
  extends BaseDisplayComponent<Lead>
  implements OnInit
{
  createdAt;
  content;

  ngOnInit() {
    if (!this.value || !this.value.notes || !this.value.notes[0]) return;

    this.createdAt = this.value.notes[0].created_at;
    this.content = this.value.notes[0].content;
  }
}
