import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from "rxjs";
import { EnumItem } from "../../../../_metronic/shared/models/enum-item";
import { ILeadsService } from "../../_services/leads-service-interface";
import { MessageService } from "../../../../_metronic/shared/services/message.service";
import { finalize, map } from "rxjs/operators";
import { Lead } from "../../_dto/lead-dto";

@Component({
  selector: 'app-change-lead-status',
  templateUrl: './change-lead-status.component.html',
  styleUrls: ['./change-lead-status.component.scss']
})
export class ChangeLeadStatusComponent implements OnInit {

  @Input() lead: Lead;
  @Input() type: string | number;
  @Input() currentStatus: number;
  @Input() small: boolean;
  @Input() custom: boolean;

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  @Output() isLoading = this.isLoadingSubject.asObservable();

  options$ = new BehaviorSubject<EnumItem[]>([]);

  constructor(private leads: ILeadsService,
              private message: MessageService) { }

  ngOnInit(): void {
    this.isLoadingSubject.next(true);
    this.leads.getEnums().then(data => { 
      this.options$.next(data?.statuses || []); 
    });
    this.isLoadingSubject.next(false);
  }

  onChange(event: EnumItem) {
    if (!event) {
      return;
    }

    this.isLoadingSubject.next(true);

    this.leads.changeStatus(this.lead.id, event.value as number)
      .pipe(
        finalize(() => this.isLoadingSubject.next(false))
      )
      .subscribe(({ message, status }) => {
        if (!status) {
          this.message.success(message);
        }
      });
  }
}
