import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUserProfileService } from 'src/app/modules/user-profile/_services/user-profile-service-interface';
import { CategoryConfigDto } from 'src/app/_dto/category-config-dto';
import { ProductsDto } from 'src/app/_dto/products-dto';
import { SelectDto } from 'src/app/_dto/select-dto';

export enum ProductTypes{
  Life,
  Household,
  Auto
}

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.scss'],
})
export class ProductsFormComponent implements OnInit, OnDestroy {

  requiredWarning = 'requiredWarning';
  productAgreement = '-agreement';

  @Output() ready = new EventEmitter<boolean>();
  @Output() updated = new EventEmitter<ProductsDto[]>();
  @Output() valid = new EventEmitter<boolean>();

  @Input() preselect: Array<Array<Number>>;

  private subscriptions: Subscription[] = [];
  private firstProductsState: ProductsDto[];

  public config: CategoryConfigDto;
  public invalid: boolean;
  public isSelected: boolean;
  public loading = false;

  constructor(
    private userProfile: IUserProfileService
  ) {
    this.ready.emit(false);
    this.valid.emit(false);
  }

  ngOnInit(){
    this.loading = true;
    const sb = this.userProfile.getCategoryConfig().subscribe(response => {
      this.config = response.data;
      this.firstProductsState = this.deepCopy(response.data.products);
      this.processPreselects();
      this.updated.emit(this.config.products);
      this.validate();
      this.ready.emit(true);
      this.loading = false;
    });
    this.subscriptions.push(sb);
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if(propName == 'preselect'){
        this.processPreselects();
      }
    }
  }

  public validate(){
    var valid = true;
    this.isSelected = false;
    this.config.products.forEach(prod => {
      var thisProductSelected = false;
      prod.companies.forEach(comp => {
        if(comp.selected){
          this.isSelected = true;
          thisProductSelected = true;
        }
      });
      if(thisProductSelected && !prod.agreement){
        valid = false;
      }
    });
    const finalValid = valid && this.isSelected;
    this.valid.emit(finalValid);
    this.invalid = !finalValid;
  }

  public scrollToError(){
    var anythingSelected = false;
    this.config.products.forEach(prod => {
      var thisProductSelected = false;
      prod.companies.forEach(comp => {
        if(comp.selected){
          anythingSelected = true;
          thisProductSelected = true;
        }
      });
      if(thisProductSelected && !prod.agreement){
        let el = document.getElementById(prod.id+this.productAgreement);
        const y = el.getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    });
    if(!anythingSelected){
      let el = document.getElementById(this.requiredWarning);
      const y = el.getBoundingClientRect().top + window.pageYOffset - 200;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  processPreselects(){
    if(!this.config?.products || !this.preselect || this.preselect.length == 0) {
      return;
    }
    this.preselect.forEach((companies, productId) => {
      this.config.products.find((product) => product.id == productId)?.companies?.forEach(company => {
        company.selected = companies.includes(company.id);
      });
    });
  }

  sortArrayByProperty(arr, prop) {
    return arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  getSelectedCompanyForProduct(product){
    return product.companies.find(company => company.selected);
  }

  public reset() {
    this.config.products = this.deepCopy(this.firstProductsState);
    this.updated.emit(this.config.products);
    this.validate();
  }

  configUpdate(forProduct){
    if(forProduct){
      forProduct.agreement = false;
    }
    this.updated.emit(this.config.products);
    this.validate();
  }

  deepCopy(object){
    return JSON.parse(JSON.stringify(object));
  }

  categorySelection(product_id){
    const companies = this.config.products.find(obj => {
      return obj.id === product_id
    })?.companies ?? []
    return companies.filter(el => el.selected);
  }

}
