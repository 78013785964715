import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ApiResponse } from '../../../_metronic/shared/models/api-response';
import { LoginResponse } from '../_responses/login.response';
import { RegisterDto } from '../_dto/register-dto';
import { RegisterResponse } from '../_responses/register.response';
import { ForgotPasswordDto } from '../_dto/forgot-password-dto';
import { ForgotPasswordResponse } from '../_responses/forgot-password.response';
import { ResetPasswordResponse } from '../_responses/reset-password.response';
import { LoginDto } from '../_dto/login-dto';
import { ResetPasswordDto } from '../_dto/reset-password-dto';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppConfig } from '../../../app-config';
import { AuthModel } from '../_models/auth.model';
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { Refresh } from '../_models/refresh.model';
import { ConfirmAgreementComponent } from 'src/app/pages/_layout/components/confirm-agreement/confirm-agreement.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum UserFlag {
  AGREEMENT_READ = "AGREEMENT_READ",
  REQUIRE_PRODUCTS_UPDATE = "REQUIRE_PRODUCTS_UPDATE",
  INACTIVE_NOTIFICATIONS = "INACTIVE_NOTIFICATIONS"
}

export abstract class IAuthService {

  currentUser$: Observable<User>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<User>;
  isLoadingSubject: BehaviorSubject<boolean>;

  protected unsubscribe: Subscription[] = [];
  protected authLocalStorageToken: string;

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(
    protected router: Router,
    protected config: AppConfig,
    protected modal: NgbModal,
  ) {
    this.authLocalStorageToken = `${this.config.appVersion}-${this.config.USERDATA_KEY}`;
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<User>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  logout() {
    this.softLogout();
    this.router.navigate(['auth','login'], {
      queryParams: {},
    });
  }

  softLogout(){
    localStorage.removeItem(this.authLocalStorageToken);
  }

  hardLogout() {
    this.softLogout();
    document.location.reload();
  }

  getUserByToken(): Observable<User> {
    const auth = this.getAuthFromLocalStorage();

    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.currentUserSubject = new BehaviorSubject<User>(auth.user);

    return of(auth.user);
  }

  userHasFlag(flag: UserFlag): boolean{
    const storedAuth = this.getAuthFromLocalStorage();
    const flags = storedAuth?.user?.userFlags ?? [];
    return flags.includes(flag);
  }

  userIsActive(): boolean{
    const storedAuth = this.getAuthFromLocalStorage();
    return storedAuth?.user?.active ? storedAuth?.user?.active == 'ACTIVATED' : false;
  }

  setAuthToLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.accessToken) {
      localStorage.setItem('enumVersion',auth.user.enumVersion);
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  setUserToLocalStorage(user: User): void {
    const storedAuth = this.getAuthFromLocalStorage();

    if (!storedAuth) {
      return;
    }

    storedAuth.user = user;
    this.currentUserSubject.next(user);

    this.setAuthToLocalStorage(storedAuth);
  }

  updateLocalStorage(user: Refresh): void {
    const storedAuth = this.getAuthFromLocalStorage();
    let redirect = false;

    if (!storedAuth) {
      return;
    }

    localStorage.setItem('enumVersion',user.enumVersion);

    if((storedAuth.user.active != user.active) && user.active == 'ACTIVATED'){
      redirect = true;
    }
    let merged = Object.assign(storedAuth.user, user); //{...storedAuth.user, ...user};
    this.currentUserSubject.next(merged);

    this.setAuthToLocalStorage(storedAuth);

    if(redirect){
      this.router.navigate(['/dashboard']);
    }
  }

  confirmAgreement(content, date) {
    if(!content || content == ''){
      return;
    }
    const modalRef = this.modal.open(ConfirmAgreementComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.date = date;
    modalRef.componentInstance.clickedConfirm.subscribe((result)=>{
      this.setFlag(UserFlag.AGREEMENT_READ).subscribe(() => {
        modalRef.close(); 
      });
    })
  }

  abstract setFlag(flag: UserFlag): Observable<ApiResponse<any>>;

  abstract changeLang(lang: string): Promise<boolean>;

  abstract login(credentials: LoginDto): Observable<ApiResponse<LoginResponse>>;

  abstract online(): Observable<boolean>;

  abstract registration(userData: RegisterDto): Observable<ApiResponse<RegisterResponse>>;

  abstract getAgreements(): Observable<ApiResponse<AgreementDto[]>>;

  abstract forgotPassword(data: ForgotPasswordDto): Observable<ApiResponse<ForgotPasswordResponse>>;

  abstract resendMail(email: any): Observable<ApiResponse>;

  abstract resetPassword(data: ResetPasswordDto): Observable<ApiResponse<ResetPasswordResponse>>;

  abstract refreshToken(token: string): Observable<ApiResponse<LoginResponse>>;

  abstract getUser(): Observable<ApiResponse<User>>;

  abstract snapshot(): Observable<ApiResponse<Refresh>>;

  abstract activateFillingDetails():  Observable<ApiResponse<boolean>>;

}
