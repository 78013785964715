import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { EnumItem } from "../../../../_metronic/shared/models/enum-item";
import { ILeadsService } from "../../_services/leads-service-interface";
import { Lead } from "../../_dto/lead-dto";
import { LeadComplaintComponent } from '../lead-complaint/lead-complaint.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { IAuthService } from 'src/app/modules/auth';

interface ButtonClassMap {
  textButton: string;
  button: string;
}

@Component({
  selector: 'app-complaint-status',
  templateUrl: './complaint-status.component.html',
  styleUrls: ['./complaint-status.component.scss']
})
export class ComplaintStatusComponent implements OnInit {

  @Input() lead: Lead;
  @Input() complaintButton: boolean = false;

  options$ = new BehaviorSubject<EnumItem[]>([]);

  public balance = 0;
  public result = null;
  public showComplaints = environment.isComplaintsEnabled;

  public buttonClasses: string[] = [];

  private statusClassMap: { [status: string]: ButtonClassMap } = {
    PROCESSING: { textButton: 'text-dark-65', button: 'btn-dark-md' },
    DECLINED: { textButton: 'text-danger', button: 'btn-danger' },
    CANCELED_BY_AGENT: { textButton: 'text-danger', button: 'btn-danger' },
    REFUNDED: { textButton: 'text-success', button: 'btn-success' }
  };

  private setButtonClasses(): void {
    const status = this.lead?.complaint?.status;
    if (!status) return;
    const classMap = this.statusClassMap?.[status];

    if (this.complaintButton) {
      this.buttonClasses = ['text-white', 'ml-2', 'font-size-sm', classMap?.button || ''];
    } else {
      this.buttonClasses = ['btn-link', 'p-0', 'text-left', classMap?.textButton || ''];
    }
  }

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  @Output() isLoading = this.isLoadingSubject.asObservable();

  constructor(
    private leadService: ILeadsService,
    private modal: NgbModal,
    protected changeDetectorRef: ChangeDetectorRef,
    public userService: IAuthService
  ) {
    this.balance = this.userService.currentUserValue.refundPoints;
  }

  ngOnInit(): void {
    this.setButtonClasses();
    // this.isLoadingSubject.next(true);
    // this.isLoadingSubject.next(false);
  }

  complaint() {
    const modalRef = this.modal.open(LeadComplaintComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.lead = this.lead;
    modalRef.componentInstance.leadService = this.leadService;
    modalRef.result.then((result) => {
      this.result = result;
      this.changeDetectorRef.detectChanges();
    }).catch(err => {})
  }

}
