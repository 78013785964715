import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-filter',
  template: `  `
})
export class BaseFilterComponent implements OnDestroy, OnInit {
  @Input() form: FormGroup;
  @Input() enums: any = {};
  @Output() filter = new EventEmitter();

  subscriptions: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  public formName = null;

  ngOnInit() {
    this.registerForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  registerForm(){ }

}