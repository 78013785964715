import { UserDropdownItem } from '../app/_metronic/partials/layout/extras/dropdown-inner/user-dropdown-inner/user-dropdown-item';

export const UserDropdownConfig: UserDropdownItem[] = [
  {translation: 'MY_ACCOUNT', icon: 'flaticon2-calendar-3', url: '/user-profile', color: 'text-warning'},
  {translation: 'MESSAGES', icon: 'fas fa-envelope-open-text', url: '/user-profile/messages', color: 'text-danger'},
  {translation: 'LOCATION_DATA', icon: 'flaticon2-world', url: '/user-profile/address-information', color: 'text-success'},
  {translation: 'BILLING_DATA', icon: 'flaticon2-file-2', url: '/user-profile/invoice-information'},
  {translation: 'PLACES_OF_SERVICES', icon: 'flaticon2-placeholder', url: '/user-profile/regions', color: 'text-info'},
  {translation: 'NOTIFICATIONS', icon: 'flaticon2-bell-4', url: '/user-profile/notifications', color: 'text-warning'},
  {translation: 'CONFIGURATOR', icon: 'flaticon2-gear', url: '/user-profile/products', color: 'text-success'},
  {translation: 'CHANGE_EMAIL', icon: 'flaticon2-mail', url: '/user-profile/change-email', color: 'text-secondary'},
  {translation: 'CHANGE_PASSWORD', icon: 'flaticon2-lock', url: '/user-profile/change-password', color: 'text-danger'},
];
