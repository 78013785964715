import { InjectionToken } from '@angular/core';

export interface AppConfig {
  production: boolean;
  defaultLang: string;
  appVersion: string;
  isMockEnabled: boolean;
  isComplaintsEnabled: boolean;
  USERDATA_KEY: string;
  apiUrl: string;
  frontUrl: string;
  backendUrl: string;
}

export interface RichImageEntry extends RichEntry{
  image: string;
}

export interface RichEntry{
  id?: number;
  name: string;
  class: string;
}

export interface LanguageObject {
  pl: string
  en: string
}

export interface MapConfig {
  apiKey: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app-config');
export const MAP_CONFIG = new InjectionToken<MapConfig>('map-config');
