import { MessagesDto } from "../../user-profile/_dto/messages-dto";

export class Refresh {
  messages: MessagesDto;
  points: number;
  refundPoints: number;
  locale: string;
  active: string;
  agreementToConfirm?: string;
  agreementDate?: string;
  enumVersion: string;
  userFlags: Array<string>;
  rates: Array<number>;

  constructor() {
  }

}
