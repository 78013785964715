import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-status-edit-display',
  template: `
  <app-complaint-status *ngIf="mobile" [lead]="value" class="d-block text-right mb-2" ></app-complaint-status>
  <app-change-lead-status [lead]="value" [currentStatus]="value.user_status.value" [small]="true"></app-change-lead-status>
  <app-complaint-status *ngIf="!mobile" [lead]="value" ></app-complaint-status>
  `
})
export class StatusEditDisplayComponent extends BaseDisplayComponent<Lead>{

}