// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
   transform(value: any, args?: any): any {
    if(value && typeof value != 'object'){
      let output = value+'';
      if(output.charAt(0) == "+"){
        return output.substring(0, 3) + ' ' + output.substring(3).toString().match(/.{3}/g).join(' ');
      }
      return output.toString().match(/.{3}/g).join(' ');
    }
    return value;
  }
}
