import { InjectionToken } from '@angular/core';
import { LanguageObject } from 'src/app/app-config';

export const SETTLEMENTS_GLOBAL_CONFIG =
  new InjectionToken<SettlementsConfig>('settlements-global-config');

export interface HeaderItem {
  childRoute: boolean;
  route: string;
  translation: string;
}

export interface PackageItem {
  id: number,
  name: string,
  oldBasePoints: number;
  basePoints: number;
  oldBonusPoints: number;
  bonusPoints: number;
  oldRefundPoints: number;
  refundPoints: number;
  oldPrice: number|undefined;
  price: number;
  flairs: string[];
}

export interface TopUpConfig {
  pointPrice: number;
  vatValue: number;
  packages: PackageItem[];
  enablePackages: boolean;
  options: number[];
  validators: [];
  autoRedirectDelay: number;
  redirectUrl: string;
}

export interface SettlementsConfig {
  headers: HeaderItem[];
  topUp: TopUpConfig;
  invoicePrefix: string;
}
