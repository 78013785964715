import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Lead, ComplaintPurchase } from "../../_dto/lead-dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import KTWizard from '../../../../../assets/js/components/wizard';
import { TranslateService } from '@ngx-translate/core';
import { ILeadsService } from '../../_services/leads-service-interface';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import { IAuthService } from 'src/app/modules/auth';
import { requiredWhen } from 'src/app/_validators/required-when.validator';

@Component({
  selector: 'app-lead-complaint',
  templateUrl: './lead-complaint.component.html',
  styleUrls: ['./lead-complaint.component.scss']
})
export class LeadComplaintComponent implements OnInit, AfterViewInit {

  @Input() lead: Lead;
  @Input() leadService: ILeadsService;

  @ViewChild('wizard', { static: true }) el: ElementRef;
  wizard: any;

  public reducedPurchases: boolean[] = [];
  public statusClass: string;

  public steps;

  public startBalance;
  public balance;
  public refund = 0;

  public selectedAmounts = [];

  public reduced = false;
  public complete = false;
  public processing = false;

  public reasons = [
    "NO_CONTACT_WITH_CLIENT",
    "CANNOT_SIGN_OFFER",
    "CANNOT_PRESENT_OFFER",
    "REGULATION_OR_LAW_BREAKING",
    "OTHER"
  ];

  private statusClassMap: { [status: string]: string} = {
    PROCESSING: 'text-dark-65',
    DECLINED: 'text-danger',
    CANCELED_BY_AGENT: 'text-danger',
    REFUNDED: 'text-success'
  };

  private setStatusClass(): void {
    const status = this.lead?.complaint?.status;
    this.statusClass = this.statusClassMap[status];
  }

  private isLoadingSubject = new BehaviorSubject(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    protected modal: NgbActiveModal,
    protected message: MessageService,
    protected translate: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef,
    public userService: IAuthService
  ) {
    this.startBalance = this.userService.currentUserValue.refundPoints;
    this.balance = this.startBalance;
  }

  ngOnInit(): void {
    this.prepareGroup();
    this.hasRefundReduction();
    this.initializeReducedPurchases();
    this.setStatusClass();
  }

  ngAfterViewInit() {
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });
  }

  validateStep(step){
    this.steps[step].markAllAsTouched();
    this.steps[step].markAsDirty();
    for (const key in this.steps[step].controls) {
      const formElement = this.steps[step].get(key);
      formElement.updateValueAndValidity();
    }
    if(this.steps[step].invalid){
      this.changeDetectorRef.detectChanges();
      return false;
    }
    return true;
  }
  
  nextStep(){
    if(!this.steps){
      this.wizard.stop();
      return;
    }
    if(!this.validateStep(this.wizard.currentStep)){
      this.wizard.stop();
      return;
    }
    this.changeDetectorRef.detectChanges();
  }

  prevStep(){
    if(!this.steps){
      this.wizard.stop();
      return;
    }
  }

  onBack() {
    if(this.complete) window.location.reload();
    this.modal.close();
  }

  onSubmit() {
    if(!this.validateStep(this.steps.length-1)){
      return;
    }
    if(this.processing) return;
    this.processing = true;
    let output = {};
    this.steps.forEach(element => {
      if(!element) return;
      Object.assign(output, element.value);
    });
    const content = this.translate.instant('LEADS.COMPLAINT.agreement_value');
    output['agreement_content'] = content;
    this.leadService.finaliseComplaint(this.lead.id, output).subscribe(
      ({message, status}) => {
        if(status == 0){
          this.message.success(message);
          this.complete = true;
          // this.modal.close(true);
        }else{
          this.message.error(message);
          // this.modal.close(false);
        }
        this.processing = false;
    });
  }

  prepareGroup(){
    var steps = [];

    steps[1] = new FormGroup({
      ics: new FormArray( [], Validators.required ),
    });

    steps[2] = new FormGroup({
      reason: new FormControl( null , Validators.required ),
      desc: new FormControl( null )
    }, requiredWhen('desc','reason','OTHER') );

    steps[3] = new FormGroup({
      agreement: new FormControl( null , Validators.requiredTrue )
    });

    this.steps = steps;
  }

  onCheckboxChange(event: any) {
    const index = event.target.value;
    const target = this.lead.bought_for[index];
    const selected = (this.steps[1].controls['ics'] as FormArray);
    if (event.target.checked) {
      selected.push(new FormControl(target.id));
    } else {
      const index = selected.controls
        .findIndex(x => x.value === target.id);
      selected.removeAt(index);
    }

    this.selectedAmounts = [];
    this.balance = this.startBalance;
    this.reduced = false;
    this.refund = 0;

    this.steps[1].controls['ics'].value.forEach(id => {

      let icIndex = this.lead.bought_for.findIndex(element => {
        return element.id == id
      });
      let ic = this.lead.bought_for[icIndex];
      if( !ic ) return;

      this.balance -= ic.price;

      if( this.balance < 0 ){
        this.selectedAmounts[icIndex] = ic.price + this.balance;
        this.reduced = true;
      }else{
        this.selectedAmounts[icIndex] = ic.price;
      }
      this.refund += this.selectedAmounts[icIndex];

    });

  }

  checkControl(isValid: boolean, step: number, controlName: string = null, controlParent: string = null): boolean {
    var base:any = this.steps[step];
    if(!controlName){
      return (isValid ? base.valid : base.errors) && (base.dirty || base.touched);
    }
    if(controlParent){
      var base:any = base.controls[controlParent];
    }
    const control = base.controls[controlName];
    return (isValid ? control.valid : control.invalid ) && (control.dirty || control.touched);
  }

  getTranslatedReason(reason: string): string {
    const translatedReason = this.translate.instant('LEADS.COMPLAINT.OPTIONS.' + reason);
    return translatedReason === 'LEADS.COMPLAINT.OPTIONS.' + reason ? reason : translatedReason;
  }

  hasRefundReduction(): void  {
    if (this.lead?.complaint?.purchases) {
      this.reduced = this.lead.complaint.purchases.some(purchase => purchase.refund < purchase.price);
    }
  }

  private initializeReducedPurchases(): void {
    this.reducedPurchases = this.lead?.complaint?.purchases?.map((purchase) =>
      this.isRefundReduced(purchase)
    ) || [];
  }

  private isRefundReduced(purchase): boolean {
    return purchase.refund < purchase.price;
  }

}
