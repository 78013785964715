import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-search-term-filter',
  template: `
  <div [formGroup]="form">
    <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.CATEGORY' | translate }}</small>
    <ng-select class="" [multiple]="true" name="category" formControlName="category"  style="min-width: 150px"
               [items]="enums.categories" bindLabel="label" bindValue="value"
               placeholder="{{ 'GLOBAL.ALL' | translate }}">
    </ng-select>

  </div>
  `
})
export class CategoriesFilterComponent extends BaseFilterComponent{

  public static formName = ['category'];

  registerForm(){
    this.form.addControl('category', new FormControl(null));

    this.subscriptions.push(
      this.form.controls.category.valueChanges.subscribe(() =>{
        this.filter.emit();
      }
    )
    );
  }

}
