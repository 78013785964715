import { AgreementDto } from "src/app/_dto/agreement-dto";

export const RegistrationConfig = {
  agreements: [
      new AgreementDto(1,'Akceptuję postanowienia <a href="https://www.leadguru.pl/polityka-prywatnosci/" class="link" target="_blank">Polityki prywatności</a>.',true,false),
      new AgreementDto(2,`Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od LeadGuru sp. z o.o. z wykorzystaniem telekomunikacyjnych urządzeń końcowych (m.in. telefon) oraz środków komunikacji elektronicznej (m.in. SMS, e-mail). <small>Ta zgoda jest nam potrzebna, abyśmy mogli skontaktować się np. w celu pomocy w rejestracji, informowaniu o nowych leadach i/lub przesyłaniu wiadomości o nowych ofertach LeadGuru. W każdej chwili można cofnąć wyrażoną zgodę. Przysługuje Ci też m.in. prawo dostępu do danych, ich usunięcia i prawo do przenoszenia.</small>`,true,false)
  ],
  logo: 'logo-letter-1.png',
  agreementLanguageForBe: 'pl'
}
