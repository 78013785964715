import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-verify-hash',
  templateUrl: './verify-hash.component.html',
  styleUrls: ['./verify-hash.component.scss']
})
export class VerifyHashComponent implements OnInit {


  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient,
              private message: MessageService) { }

  ngOnInit() {
    const hash = this.route.snapshot.paramMap.get('hash');

    this.http.get<any>(environment.backendUrl + `/auth/verify/${hash}`).subscribe(
      response => {
        if (!response.status) {
          this.message.success(response.message);
        }else{
          this.message.error(response.message || 'Error');
        }
        this.redirectToMainPage();
      }
    );
  }

  redirectToMainPage() {
    this.router.navigate(['/']);
  }
}
