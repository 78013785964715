import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { ILeadsService } from '../../_services/leads-service-interface';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-price-display',
  template: `
  <div style="line-height: 14px; display:inline-block">
    <span class="font-size-h4 font-weight-bolder text-primary">
      {{ value.exclusiveLead ? value.premiumPrice : ( value.bought ? leadService.priceSum(value,true) : (value.promoPrice || value.price) ) }}
    </span>
    <strike class="ml-1" *ngIf="value.bought && (leadService.priceSum(value) != leadService.priceSum(value,true))" >
      {{ leadService.priceSum(value) }}
    </strike>
    <strike class="ml-1" *ngIf="!value.bought && value.promoPrice" >
      {{ value.price }}
    </strike>
    <div *ngIf="value.bought_for && !value.exclusiveLead" style="max-width: 150px; white-space: break-spaces;">
      <span *ngFor="let company of value.bought_for; let last = last;" [title]="company.long_name" class="my-0">
        <small class="font-weight-bolder">{{ company.short_name + (last ? '' : ', ')}}</small>
      </span>
    </div>
    <p *ngIf="value.exclusiveLead" class="font-weight-bolder text-success mb-0"><small>{{ 'LEADS.LEAD_LIST.EXCLUSIVE' | translate }}!</small></p>
    <p *ngIf="value.canBeBoughtOut && !value.exclusiveLead" class="font-weight-bolder text-info mb-0"><small>{{ 'LEADS.LEAD_LIST.BUY_OUT' | translate }}!</small></p>
  </div>
  `
})
export class PriceDisplayComponent extends BaseDisplayComponent<Lead>{

  constructor(public leadService: ILeadsService) {
    super();
  }

}
