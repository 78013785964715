import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilterComponent } from '../custom-base/filter-base.component';


@Component({
  selector: 'app-search-term-filter',
  template: `
    <div [formGroup]="form">
      <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.PROVINCE' | translate }}</small>
      <ng-select [multiple]="true" class="" name="province" placeholder="{{ 'GLOBAL.ALL' | translate }}"
                  formControlName="province" [items]="enums.provinces" bindLabel="name" bindValue="name">
      </ng-select>
    </div>
  `
})
export class ProvincesFilterComponent extends BaseFilterComponent{

  public static formName = ['province'];

  registerForm(){
    this.form.addControl('province', new FormControl(null));

    this.subscriptions.push(
      this.form.controls.province.valueChanges.subscribe(() =>{
          this.filter.emit();
        }
      )
    );
  }

}
