import { InjectionToken, Type } from '@angular/core';

export const LEADS_GLOBAL_CONFIG =
  new InjectionToken<LeadsConfig>('leads-global-config');


export interface LeadsTableConfig {
  filters: any[],
  showClearFilters: boolean;
  columns: ColumnConfig[];
  actions: Type<any>[];
}

export interface LeadsConfig {
  list: LeadsTableConfig;
  widget: LeadsTableConfig;
  boughtWidget: LeadsTableConfig;
  boughtList: LeadsTableConfig;
  purchaseExtension: Type<any>;
}

export interface ColumnConfig {
  name: string;
  label: string;
  sortable: boolean;
  component?: Type<any>;
  hideLabelOnMobile?: boolean;
}
