import { AbstractControl, ValidatorFn } from "@angular/forms";

export function tin(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if(!control.value){
      return null;
    }
    
    const tin = control.value?.toString() || '';
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

    if (tin?.length !== 10) {
      return { length: true };
    }

    let sum = 0;

    for (let i = 0; i < 9; i++) {
      sum += weights[i] * parseInt(tin[i]);
    }

    const controlDigit = sum % 11;

    return controlDigit === parseInt(tin[9])
      ? null
      : { invalidControlDigit: true };
  };
}
