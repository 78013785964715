<!--begin::Languages-->
<div class="dropdown" ngbDropdown placement="bottom-right" [autoClose]="true" *ngIf="!mini">
  <!--begin::Toggle-->
  <div
    ngbDropdownToggle
    class="topbar-item"
    data-toggle="dropdown"
    data-offset="10px,0px"
  >
    <div
      class="btn btn-icon btn-soft-background btn-dropdown btn-lg mr-small"
    >
      <img
        class="h-20px w-20px rounded-sm"
        src="{{ language?.flag }}"
        alt="language"
      />
    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div
    ngbDropdownMenu
    class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right mt-4"
  >
    <ul class="navi navi-hover py-4">
      <ng-container *ngFor="let language of languages">
        <li class="navi-item" [ngClass]="{ active: language.active }">
          <a
            href="javascript:;"
            (click)="setLanguageWithRefresh(language.lang)"
            [ngClass]="{ active: language.active }"
            class="navi-link"
          >
            <span class="symbol symbol-20 mr-3">
              <img src="{{ language.flag }}" />
            </span>
            <span class="navi-text">{{ language.name }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <!--end::Dropdown-->
</div>
<!--end::Languages-->
<div *ngIf="mini" class="p-4 p-xxl-5 font-size-h5">
  <ng-container *ngFor="let language of languages; first as isFirst">
      <span *ngIf="!isFirst" class="m-2"> | </span>
      <a
        href="javascript:;"
        (click)="setLanguageWithRefresh(language.lang)"
        [ngClass]="{ 'selected-lang': language.active }"
      >
        <span class="text-secondary text-uppercase"
        data-toggle="tooltip" data-placement="right" title="{{ language.name }}">
          {{ language.lang }}
        </span>
      </a>
  </ng-container>
</div>
