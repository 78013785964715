import { AbstractControl, ValidatorFn } from "@angular/forms";

export function HundredMultipeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        var error = {notHundredMultipe: true};
        if(!Number.isInteger(control.value)){
            return error;
        }
        if(control.value < 0){
            return error;
        }
        if(control.value % 100 != 0){
            return error;
        }
        return null;
    };
}


