import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-location-display',
  template: `
    <span *ngIf="value.location.city; else onlyCounty" style="line-height: 14px; display:inline-block;">
      <strong>{{value?.location?.city}}</strong>
      <br>
      <small>
        <span *ngIf="value?.location?.postalCode">{{value?.location?.postalCode | postalCode}}</span> 
        <span *ngIf="value?.location?.province"> ({{value?.location?.province}})</span>
      </small>
    </span>
    <ng-template #onlyCounty>
      <span *ngIf="value.location.county; else onlyProvince" style="line-height: 14px; display:inline-block;">
        <strong>pow. {{value?.location?.county}}</strong>
        <br>
        <small>
          <span *ngIf="value?.location?.province"> ({{value?.location?.province}})</span>
        </small>
      </span>
    </ng-template>
    <ng-template #onlyProvince>
      <span *ngIf="value.location.province; else onlyCode" style="line-height: 14px; display:inline-block;">
        <strong>woj. {{value?.location?.province}}</strong>
      </span>
    </ng-template>
    
    <ng-template #onlyCode>
      <strong>
        {{ value?.location?.postalCode || value.rawPostalCode | postalCode }} 
      </strong>
    </ng-template>
  `
})
export class LocationDisplayComponent extends BaseDisplayComponent<Lead> {

}