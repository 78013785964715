import { Component, OnInit } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-category-display',
  template: `
  <div [ngClass]="{'floater-top-right' : mobile}">
    <i class="btn btn-icon btn-light btn-text cursor-default" [ngbTooltip]="value?.category?.name">
      <span [inlineSVG]="value?.category?.image" cacheSVG="true"
        class="svg-icon svg-icon-md {{value?.category?.class}}">
      </span>
    </i>
    <span *ngIf="!mobile">
      {{ value?.category?.name }}
    </span>
  </div>
  `
})
export class CategoryDisplayComponent extends BaseDisplayComponent<Lead> {

}