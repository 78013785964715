import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-display',
  template: `
    {{value}}
  `
})
export class BaseDisplayComponent<T> {
  @Input() value: T;
  @Input() mobile: boolean = false;
}