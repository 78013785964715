import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit, Inject,
  NgZone
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { TranslateService } from "@ngx-translate/core";
import { APP_CONFIG, AppConfig, MAP_CONFIG, MapConfig } from "./app-config";
import { IAuthService } from './modules/auth';
import { DOCUMENT } from '@angular/common';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private translate: TranslateService,
    private authService: IAuthService,
    @Inject(DOCUMENT) private doc,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(MAP_CONFIG) public mapConfig: MapConfig,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.translate.use(this.config.defaultLang);

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.authService.snapshot().subscribe(result => {
          if(!result){
            this.authService.logout();
            document.location.reload();
          }
        });
        // clear filtration paginations and others
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        link.setAttribute('href', this.doc.URL);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          const slug = event.url.split(".pl").pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
  });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
