export class AgreementDto {

  public id: number;
  public content: string;
  public required: boolean;
  public disclaimer: boolean;
  public accepted: boolean;

  constructor(id, content, required, accepted, disclaimer = false) {
    this.id = id;
    this.content = content;
    this.required = required;
    this.accepted = accepted;
    this.disclaimer = disclaimer;
  }

}
