<div *ngIf="showComplaints">
    <ng-container *ngIf="result != null; else clean">
        <span [ngClass]="complaintButton ? 'btn btn-info text-white ml-2 font-size-sm' : 'text-danger'">
            {{ ( result ? 'LEADS.COMPLAINT.HAS_BEEN_SENT' : 'LEADS.COMPLAINT.TRY_AGAIN_LATER' ) | translate }}
        </span>
    </ng-container>
    <ng-template #clean>
        <button 
            [disabled]="balance <= 0"
            [ngClass]="complaintButton ? 'btn btn-info text-white ml-2 font-size-sm' : 'btn btn-link p-0 text-info text-left'"
            [ngStyle]="{
                'font-size': !complaintButton ? '12px' : null
            }"
            *ngIf="lead.bought && !lead.refunded && lead.refundable && !lead?.complaint?.id"
            (click)="complaint()">
            {{ 'LEADS.COMPLAINT.SUBMIT_COMPLAINT' | translate }}
        </button>
        <button
            class="btn"
            [ngClass]="buttonClasses"
            [ngStyle]="{
                'line-height': !complaintButton ? '14px' : null,
                'font-size': !complaintButton ? '12px' : null
            }"
            *ngIf="lead?.complaint && lead?.complaint?.id"
            (click)="complaint()">
            {{ 'LEADS.COMPLAINT.STATUS_SHORT' | translate }} {{ ('LEADS.COMPLAINT.STATUSES.' + lead.complaint.status) | translate | lowercase }}
        </button>
    </ng-template>
</div>