import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseFilterComponent } from '../custom-base/filter-base.component';

@Component({
  selector: 'app-search-term-filter',
  template: `
  <div [formGroup]="form">
    <small class="form-text text-muted mb-1">{{ 'LEADS.LEAD_LIST.FILTER_BY.LEAD_ID' | translate }}</small>
    <input type="text" class="form-control" name="leadId" placeholder="{{ 'LEADS.LEAD_LIST.PLACEHOLDERS.LEAD_ID' | translate }}" value=""
      formControlName="leadId" />
  </div>
  `
})
export class LeadIdFilterComponent extends BaseFilterComponent{
  
  public static formName = ['leadId'];

  registerForm(){
    this.form.addControl('leadId', new FormControl(''));

    this.subscriptions.push(
      this.form.controls.leadId.valueChanges.pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.filter.emit())
    );
  }

}